<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ThreatMonitoring')}}</p>
      <div>
        <el-form
          label-width="151px"
          class="demo-ruleForm"
          style="padding: 0 120px"
        >
          <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ApplicationThreat')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ApplicationThreatContent')}}
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <!--   -->
            <el-switch
              @change="test"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.threat.use"
            ></el-switch>
          </el-form-item>
          <div v-show="flagtrue">
            <el-form ref="form" label-width="200px" style="margin-left: -26px">
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.InjectionAttack')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.inject.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.inject.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio1(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio1(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio1(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio1(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.FrameAttack')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.frame.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.frame.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio2(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio2(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio2(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio2(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DebugBehavior')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.debug.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.debug.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio3(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio3(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio3(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio3(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.LocationFraud')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.location.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.location.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio4(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio4(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio4(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio4(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DomainNameFraud')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.domain.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.domain.action"
                  @change="checkbox"
                >
                <el-radio :label="4" @click.native.prevent="checkboxRadio5(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio5(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio5(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio5(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ProgramPlugIn')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.plugin.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.plugin.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio6(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio6(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio6(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio6(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ApplicationCracking')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.signature.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.signature.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio7(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio7(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio7(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio7(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.HTTPSHijacking')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.https_ca.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.https_ca.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio8(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio8(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio8(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio8(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.HTTPProxy')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.wifi_proxy.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.wifi_proxy.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio9(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio9(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio9(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio9(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ApplicationMoreOpen')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.multi_apk.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.multi_apk.action"
                  @change="checkbox"
                >
                <el-radio :label="4" @click.native.prevent="checkboxRadio10(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio10(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio10(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio10(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.FrameworkSoftware')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.frame_attach.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.frame_attach.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio11(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio11(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio11(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio11(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.RiskApplication')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.danger_apps.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.danger_apps.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio12(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio12(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio12(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio12(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.MemoryTampering')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.memory_change.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.memory_change.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio22(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio22(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio22(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio22(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ROOTJailbreak')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.root_status.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.root_status.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio13(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio13(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio13(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio13(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.USBDebugging')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.adb.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.adb.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio14(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio14(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio14(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio14(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.simulator')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.vm.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.vm.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio15(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio15(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio15(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio15(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              
              
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.SimulatedPositionAllowed')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.mock_location.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.mock_location.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio16(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio16(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio16(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio16(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.TheRootCertificateIsAbnormal')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.sys_user_ca.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.sys_user_ca.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio17(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio17(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio17(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio17(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CustomROM')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.rom_custom.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.rom_custom.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio18(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio18(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio18(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio18(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.EquipmentForgery')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.false_device.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.false_device.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio23(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio23(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio23(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio23(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 新增  6.0.0  Android检测项-->
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.TheScreenLockPasswordIisNotEnabled')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.screen_password.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.screen_password.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio19(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio19(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio19(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio19(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.SystemTimeException')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.system_time.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.system_time.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio20(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio20(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio20(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio20(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 6.0.2新增威胁监测指标 -->
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.Windows')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.windows.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.windows.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio24(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio24(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio24(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio24(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.macOS')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.macOS.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.macOS.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio25(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio25(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio25(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio25(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.VPN')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.vpn.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.vpn.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio26(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio26(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio26(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio26(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CloudPhone')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.cloud_phone.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.cloud_phone.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio27(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio27(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio27(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio27(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.RiskIP')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.danger_ip.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.danger_ip.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio28(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio28(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio28(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio28(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- <el-form-item label="截屏阻断解除：">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.threat.screenshot_status.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.threat.screenshot_status.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio21(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio21(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio21(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ThreatMonitoringTab.ExitApplication')}}</el-radio>
                </el-radio-group>
              </el-form-item> -->
              
            </el-form>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detection: {
      type: Object,
      default: () => {},
    },
    type:{
       type:String,
       default:""
     }
  },
  data() {
    return {
      flagtrue: false,
      permission: {
        threat: {
          use: "0",
          inject: {
            collect: "0",
            action: "0",
          },
          frame: {
            collect: "0",
            action: "0",
          },
          debug: {
            collect: "0",
            action: "0",
          },
          location: {
            collect: "0",
            action: "0",
          },
          domain: {
            collect: "0",
            action: "0",
          },
          plugin: {
            collect: "0",
            action: "0",
          },
          signature: {
            collect: "0",
            action: "0",
          },
          memory_change: {
            collect: "0",
            action: "0",
          },
          false_device:{//设备伪造
		        action: "0" , //响应动作 
		        collect: "0" ,//采集行为 0 未勾选 16 勾选
			    },

          https_ca: {
            collect: "0",
            action: "0",
          },
          wifi_proxy: {
            collect: "0",
            action: "0",
          },
          multi_apk: {
            collect: "0",
            action: "0",
          },
          frame_attach: {
            collect: "0",
            action: "0",
          },
          danger_apps: {
            collect: "0",
            action: "0",
          },
          root_status: {
            collect: "0",
            action: "0",
          },
          adb: {
            collect: "0",
            action: "0",
          },
          vm: {
            collect: "0",
            action: "0",
          },
          mock_location: {
            collect: "0",
            action: "0",
          },
          sys_user_ca: {
            collect: "0",
            action: "0",
          },
          rom_custom: {
            collect: "0",
            action: "0",
          },
          windows:{
            collect: '0',
            action: '0'
          },
          macOS:{
            collect: '0',
            action: '0'
          },
          vpn:{
            collect: '0',
            action: '0'
          },
          cloud_phone:{
            collect: '0',
            action: '0'
          },
          danger_ip:{
            collect: '0',
            action: '0'
          },
          // 6.0.0建行新加检测项
          screen_password:{							//锁屏密码未开启 采集、动作传值与其他参数保持一致
						collect:"0",
						action:"0"
					},
					system_time:{							//系统时间异常 采集、动作传值与其他参数保持一致
						collect:"0",
						action:"0"
					},
					screenshot_status:{					//截屏阻断解除 采集、动作传值与其他参数保持一致
						collect:"0",
						action:"0"
					},
					memory_change:{					//截屏阻断解除 采集、动作传值与其他参数保持一致
						collect:"0",
						action:"0"
					}
        },
      },
    };
  },
  created() {// 威胁检测
    if (this.detection) {
      if (
        this.permission.threat != this.detection.appPermission.permission.threat
      ) {
        this.detection.appPermission.permission.threat.sys_user_ca
        this.permission.threat = this.detection.appPermission.permission.threat;
        this.permission.threat.use =
          this.detection.appPermission.permission.threat.use;
        this.test(this.permission.threat.use);
      } 
    }
  },
  methods: {
     checkboxRadio1(value){
      this.permission.threat.inject.action = value == this.permission.threat.inject.action ? '' : value 
    },
    checkboxRadio2(value){
      this.permission.threat.frame.action = value == this.permission.threat.frame.action ? '' : value 
    },
    checkboxRadio3(value){
      this.permission.threat.debug.action = value == this.permission.threat.debug.action ? '' : value 
    },
    checkboxRadio4(value){
      this.permission.threat.location.action = value == this.permission.threat.location.action? '' : value 
    },
    checkboxRadio5(value){
      this.permission.threat.domain.action = value == this.permission.threat.domain.action ? '' : value 
    },
    checkboxRadio6(value){
      this.permission.threat.plugin.action = value == this.permission.threat.plugin.action ? '' : value 
    },
    checkboxRadio7(value){
      this.permission.threat.signature.action = value == this.permission.threat.signature.action ? '' : value 
    },
    checkboxRadio8(value){
      this.permission.threat.https_ca.action = value == this.permission.threat.https_ca.action ? '' : value 
    },
    checkboxRadio9(value){
      this.permission.threat.wifi_proxy.action = value == this.permission.threat.wifi_proxy.action ? '' : value 
    },
    checkboxRadio10(value){
      this.permission.threat.multi_apk.action = value == this.permission.threat.multi_apk.action ? '' : value 
    },
    checkboxRadio11(value){
      this.permission.threat.frame_attach.action = value == this.permission.threat.frame_attach.action ? '' : value 
    },
    checkboxRadio12(value){
      this.permission.threat.danger_apps.action = value == this.permission.threat.danger_apps.action ? '' : value 
    },
    checkboxRadio13(value){
      this.permission.threat.root_status.action = value == this.permission.threat.root_status.action ? '' : value 
    },
    checkboxRadio14(value){
      this.permission.threat.adb.action = value == this.permission.threat.adb.action ? '' : value 
    },
    checkboxRadio15(value){
      this.permission.threat.vm.action = value == this.permission.threat.vm.action ? '' : value 
    },
    checkboxRadio16(value){
      this.permission.threat.mock_location.action = value == this.permission.threat.mock_location.action ? '' : value 
    },
    checkboxRadio17(value){
      this.permission.threat.sys_user_ca.action = value == this.permission.threat.sys_user_ca.action ? '' : value 
    },
    checkboxRadio18(value){
      this.permission.threat.rom_custom.action = value == this.permission.threat.rom_custom.action ? '' : value 
    },
    checkboxRadio19(value){ 
      this.permission.threat.screen_password.action = value == this.permission.threat.screen_password.action ? '' : value 
    },
    checkboxRadio20(value){ 
      this.permission.threat.system_time.action = value == this.permission.threat.system_time.action ? '' : value 
    },
    checkboxRadio21(value){ 
      this.permission.threat.screenshot_status.action = value == this.permission.threat.screenshot_status.action ? '' : value 
    },
    checkboxRadio22(value){ 
      this.permission.threat.memory_change.action = value == this.permission.threat.memory_change.action ? '' : value 
    },
    checkboxRadio23(value){ 
      this.permission.threat.false_device.action = value == this.permission.threat.false_device.action ? '' : value 
    },
    checkboxRadio24(value){ 
      this.permission.threat.windows.action = value == this.permission.threat.windows.action ? '' : value 
    },
    checkboxRadio25(value){ 
      this.permission.threat.macOS.action = value == this.permission.threat.macOS.action ? '' : value 
    },
    checkboxRadio26(value){ 
      this.permission.threat.vpn.action = value == this.permission.threat.vpn.action ? '' : value 
    },
    checkboxRadio27(value){ 
      this.permission.threat.cloud_phone.action = value == this.permission.threat.cloud_phone.action ? '' : value 
    },
    checkboxRadio28(value){ 
      this.permission.threat.danger_ip.action = value == this.permission.threat.danger_ip.action ? '' : value 
    },
    test(val) {
      if (this.permission.threat.use == 1) {
        this.flagtrue = true;
      } else {
        this.flagtrue = false;
      }
      this.$emit("threat", this.permission);
    },
    checkbox(val) { 
       this.$emit("threat", this.permission);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
   margin-bottom: 0px; 
}
</style>