<template>
  <div> 
    <p style="padding-left: 110px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DataLeakagePrevention')}}</p>
    <div>
      <el-form ref="form" label-width="110px" class="demo-ruleForm" style="padding: 0 120px">
        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.screenWater')">
          <el-tooltip placement="right" effect="light">
            <div slot="content" style="color: #807e7e">
              <!-- 应用界面显示水印，可对信息来源<br />追溯 -->
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.waterContent1')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.waterContent2')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.waterContent3')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
          </el-tooltip>
          <el-switch @change="test" active-value="1" inactive-value="0" style="padding-left: 15px" v-model="permission.watermark.use"></el-switch>
        </el-form-item>
        <div style="display: flex; flex-direction: column; padding-left: 103px" v-show="formflag">
          <div style="margin-bottom: 10px">
            <el-radio-group v-model="permission.watermark.type" style="margin-left: 50px" @change="changeEvents">
              <el-radio :label="'0'">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.BrightWatermark')}}</el-radio>
              <el-radio :label="'1'">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DarkWatermark')}}</el-radio>
            </el-radio-group>
          </div>
          <div style="display: flex; padding: 0 40px" v-show="waterListK">
            <div style="width: 295px; margin: 0 10px 0 9px">
              <el-table ref="multipleTable" :data="dictData" stripe size="mini" tooltip-effect="dark" class="sort_table" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
                <el-table-column header-align="left" align="left" prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Names')" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column header-align="left" align="left" prop="" :label="$t('public.Controls')" show-overflow-tooltip>
                  <!-- <template v-slot="{ row }"> -->
                  <img @click="delwater" src="@/images/icon_del.png" alt="" style="
                    width: 15px;
                      height: 15px;
                      margin-left: 5px;
                      cursor: pointer;
                    " />
                  <!-- </template> -->
                </el-table-column>
              </el-table>
            </div>
            <div>
              <el-button size="mini" type="primary" @click="responseBtn" icon="el-icon-plus">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.screenWater')}}</el-button>
            </div>
          </div>
        </div>
        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.fileEncipher')" v-if="type !== 'ios'">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.fileContent1')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.fileContent2')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.fileContent3')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>
          <el-switch @change="test2" active-value="1" inactive-value="0" v-model="permission.encryptAndDecrypt.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>
        <!-- 安全键盘  改为  安全输入 -->
        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.SecureInput')">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.SecureContent')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>
          <el-switch @change="test3" active-value="1" inactive-value="0" v-model="permission.safeInput.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>
        <div class="shuRuFa" v-show="checkedBox" style="margin-left: 103px">
          <div style="margin-bottom: 10px">
            <el-checkbox :disabled="checkedSystem" true-label="1" false-label="0" @change="systemMethods" v-model="permission.safeInput.systemInput" style="padding-left: 45px">
              <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ThirdPartyInputMethodsAreProhibited')}}</span>
            </el-checkbox>
          </div>
          <div style="margin-bottom: 10px">
            <el-checkbox :disabled="checkedAction" true-label="16" false-label="0" @change="actionMethods" v-model="permission.safeInput.collect" style="padding-left: 45px">
              <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CollectionBehavior')}}</span>
            </el-checkbox>
          </div>
          <div style="margin-bottom: 10px; margin-left: 43px">
            <p style="margin-bottom: 10px; color: #606266; font-size: 14px">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ResponseAction')}}
            </p>
            <el-radio-group v-model="permission.safeInput.action" @change="safeKeyRadios">
              <el-radio :label="4" @click.native.prevent="checkboxRadio9(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AlarmMessage')}}</el-radio>
              <el-radio :label="8" @click.native.prevent="checkboxRadio9(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearData')}}</el-radio>
              <el-radio :label="2" @click.native.prevent="checkboxRadio9(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ExitApplication')}}</el-radio>
              <el-radio :label="64" @click.native.prevent="checkboxRadio9(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DenyAccess')}}</el-radio>
            </el-radio-group>
          </div>
          <div style="margin-bottom: 10px">
            <el-checkbox :disabled="checkedInput" true-label="1" false-label="0" @change="safeKeyMethods" v-model="permission.safeInput.safeKeyboard" style="padding-left: 45px">
              <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.SecurityKeyboard')}}</span>
            </el-checkbox>
          </div>
        </div>
        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ScreenCaptureRecordProtection')">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.TakeAScreenshotContent1')}} <br />{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.TakeAScreenshotContent2')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>
          <el-switch @change="testjt" active-value="1" inactive-value="0" v-model="permission.screen.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>

        <div v-show="jietu" style="margin-left: 131px">
          <!-- <div v-if="type !== 'ios'" style="margin-bottom: 10px"> -->
          <!-- <el-radio-group v-model="permission.screen.enable" @change="isnot"> -->
          <!-- <el-radio
                :label="1"
                @click.native.prevent="checkboxRadio1(1)"
                style="margin-left: 15px"
              >
                <span v-if="type !== 'ios'">
                  禁止系统对此应用的截屏/录屏操作</span
                >
              </el-radio> -->
          <!-- </el-radio-group> -->
          <!-- </div> -->
          <div style="margin-bottom: 10px"> 
            <el-radio-group v-model="permission.screen.upload" style="display:flex;flex-direction: column;">
              <!--  -->
              <el-radio v-if="type !== 'ios'" :label="3" @click.native.prevent="checkboxRadio2(3)" style="margin-left: 15px">
                <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.TheSystemCannotCaptureOrRecordTheScreenOfThisApplication')}}</span>
              </el-radio> 
              <el-checkbox v-if="type !== 'android'" true-label="1" false-label="0" @change="IosCheckboxRadio2" v-model="permission.screen.enable" style="margin-left: 15px">
               <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.TheSystemCannotCaptureOrRecordTheScreenOfThisApplication')}}</span>
             </el-checkbox>
              <el-radio :label="0" @click.native.prevent="checkboxRadio2(0)" style="margin-left: 15px;margin-top:10px;">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CollectionBehavior')}}</el-radio>
              <el-radio :label="1" @click.native.prevent="checkboxRadio2(1)" style="margin-left: 15px;margin-top:10px;">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CaptureBehaviorAndUploadScreenshotInformation')}}</el-radio>
            </el-radio-group>
          </div>

          <div style="   margin-left: 16px;
              color: #606266;
              font-size: 14px;
              font-family: Microsoft YaHei;
              margin-bottom: 10px;
            ">
            {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ResponseAction')}}
          </div>
          <div style="margin-bottom: 10px">  
            <el-radio-group v-model="permission.screen.action">
              <el-radio @click.native.prevent="checkboxRadio3(4)" :disabled="disabled" :label="4" style="margin-left: 15px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AlarmMessage')}}</el-radio>
              <el-radio :disabled="disabled" @click.native.prevent="checkboxRadio3(8)" :label="8">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearData')}}</el-radio>
              <el-radio :disabled="disabled" @click.native.prevent="checkboxRadio3(2)" :label="2">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ExitApplication')}}</el-radio>
              <el-radio :disabled="disabled" @click.native.prevent="checkboxRadio3(64)" :label="64">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DenyAccess')}}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.SystemSharing')">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.shareContent1')}} <br />
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.shareContent2')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>
          <el-switch @change="testsystem" active-value="1" inactive-value="0" v-model="permission.sysShare.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>
        <!-- 原来是 v-show="sysflag"-->
        <div class="bigbigbox" v-show="shareBox" style="margin-left: 103px">
          <!-- 勿删 -->
          <!-- <div style="margin-bottom: 10px">
            <el-checkbox :disabled="checked" true-label="1" false-label="0" @change="morechecked1" v-model="permission.sysShare.enable" style="padding-left: 45px">
              <span v-if="type !== 'ios'">禁止应用调用系统分享</span>
              <span v-else>禁止应用调用系统分享</span>
            </el-checkbox>
            <span v-show="spanflag" style="margin-left: 35px; color: #e84646">配置此项将禁止当前应用数据复制粘贴到其他任何应用</span>
          </div> -->
          <!-- 原来 禁止分享改为两个单选 【禁止分享文件到无防护应用】/【禁止分享】 -->
            <!-- <el-checkbox :disabled="shareFlag" true-label="1" false-label="0" @change="shareMethods" v-model="permission.sysShare.forbidShare.use" style="padding-left: 45px">
              <span>禁止分享</span>
            </el-checkbox> -->  
            <!-- permission.sysShare.forbidShare.use 给他传值 2选一  不选为0 选中为1 -->
          <el-radio-group v-model="permission.sysShare.shareType" style="display: flex;flex-direction: column;">  
            <el-radio :disabled="disabled" :label="1" @click.native.prevent="shareMethods(1)" style="margin-left: 44px;margin-top:10px;margin-bottom: 10px;">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DisableFileSharingToUnprotectedApplications')}}</el-radio> 
            <div v-show="fileTypeFlag" style="margin-left: 12px;">
             <el-form label-width="100px">
                <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileType')" style="margin-top: -4px; margin-left: 40px" required>
                  <div style="display: flex">
                    <el-radio-group style="line-height: 38px;" v-model="permission.sysShare.forbidUnShare.fileTypes[0]">
                      <el-radio @change="allfileTypes(0)" :disabled="allFile" :label="0">
                        {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AllFile')}}
                      </el-radio>
                      <!-- 除所有文件  外  其他 为自定义 最多输入100个字符 英文分割、不区分大小写 -->

                      <el-radio @change="allfileTypes(1)" :label="1" style="margin-left: 15px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Custom')}}</el-radio>
                      <!-- onkeyup="this.value=this.value.replace(/[^a-zA-Z]/g,'')" -->
                      <!-- \a-\z\A-\Z0-9\u4E00-\u9FA5\@\. -->
                      <el-input :disabled="diYi" @change="inputXix(permission.sysShare.abcd2)" style="width: 120px" @input="(e) => (permission.sysShare.abcd2 = Geography(e))" maxlength="100" size="mini" v-model="permission.sysShare.abcd2"></el-input>
                    </el-radio-group>

                  </div>
                </el-form-item>
                <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileName')" style="margin-left: 39px">
                  <span style="color: #606266; margin-right: 6px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.contain')}}</span>
                  <el-input style="width: 120px" @change="inpbaohan" maxlength="100" size="mini" v-model="permission.sysShare.forbidUnShare.fileNames"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <el-radio :disabled="disabled" :label="2" @click.native.prevent="shareMethods(2)" style="margin-left: 44px;margin-top:10px;margin-bottom: 10px;">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DoNotShare')}}</el-radio> 
            <div v-show="fileTypeFlag2" style="margin-left: 12px;">
              <el-form label-width="100px">
                <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileType')" style="margin-top: -4px; margin-left: 40px" required>
                  <div style="display: flex">
                    <el-radio-group style="line-height: 38px;" v-model="permission.sysShare.forbidShare.fileTypes[0]">
                      <el-radio @change="allfileTypes2(0)" :disabled="allFile" :label="0">
                        {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AllFile')}}
                      </el-radio>
                      <!-- 除所有文件  外  其他 为自定义 最多输入100个字符 英文分割、不区分大小写 -->
                      <el-radio @change="allfileTypes2(1)" :label="1" style="margin-left: 15px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Custom')}}</el-radio> 
                      <el-input :disabled="diYi2" @change="inputXix2(permission.sysShare.abcd)" style="width: 120px" @input="(e) => (permission.sysShare.abcd = Geography(e))" maxlength="100" size="mini" v-model="permission.sysShare.abcd"></el-input>
                    </el-radio-group>

                  </div>
                </el-form-item>
                <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileName')" style="margin-left: 39px">
                  <span style="color: #606266; margin-right: 6px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.contain')}}</span>
                  <el-input style="width: 120px" @change="inpbaohan" maxlength="100" size="mini" v-model="permission.sysShare.forbidShare.fileNames"></el-input>
                </el-form-item>
              </el-form>
            </div>
            </el-radio-group>  
          <div style="margin-bottom: 10px">
            <el-checkbox :disabled="shareFile" true-label="1" false-label="0" @change="shareWen" v-model="permission.sysShare.fileShareEncrypt.use" style="padding-left: 45px;margin-top: 2px;">
              <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileSharingEncryption')}}</span>
            </el-checkbox>
          </div>
          <div style="margin-bottom: 10px">
            <el-checkbox :disabled="shareImg" true-label="1" false-label="0" @change="shareImage" v-model="permission.sysShare.imgShareWatermark.use" style="padding-left: 45px">
              <span>{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.PhotoSharingWithWatermark')}}</span>
            </el-checkbox>
          </div>
          <!-- 写两个界面水印 -->
          <div style="display: flex; padding: 0 40px" v-show="imageShareFlag">
            <div style="width: 295px; margin: 0 10px 0 9px">
              <el-table ref="sharemultipleTable" :data="imgTableData" stripe size="mini" tooltip-effect="dark" class="tableClass" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
                <el-table-column header-align="left" align="left" prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Names')" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column header-align="left" align="left" prop="" :label="$t('public.Controls')" show-overflow-tooltip>
                  <!-- <template v-slot="{ row }"> -->
                  <img @click="twoDel" src="@/images/icon_del.png" alt="" style="
                      width: 15px;
                      height: 15px;
                      margin-left: 5px;
                      cursor: pointer;
                    " />
                  <!-- </template> -->
                </el-table-column>
              </el-table>
            </div>
            <div>
              <el-button size="mini" type="primary" @click="imagesBtn" icon="el-icon-plus">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.screenWater')}}</el-button>
            </div>
          </div>
          <div style="margin-bottom: 10px">
            <el-checkbox :disabled="shareCai" true-label="16" false-label="0" @change="shareBehavior" v-model="permission.sysShare.collect" style="padding-left: 45px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CollectionBehavior')}}</el-checkbox>
          </div>
          <div style="padding-left: 43px">
            <p style="margin-bottom: 10px;margin-left: 2px; color: #606266; font-size: 14px">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ResponseAction')}}
            </p>
            <el-radio-group v-model="permission.sysShare.action">
              <el-radio :label="4" @click.native.prevent="checkboxRadio6(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AlarmMessage')}}</el-radio>
              <el-radio :label="8" @click.native.prevent="checkboxRadio6(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearData')}}</el-radio>
              <el-radio :label="2" @click.native.prevent="checkboxRadio6(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ExitApplication')}}</el-radio>
              <el-radio :label="64" @click.native.prevent="checkboxRadio6(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DenyAccess')}}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CVProtection')">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CVContent1')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CVContent2')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>
          <el-switch @change="copy" active-value="1" inactive-value="0" v-model="permission.copyPaste.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>
        <div class="bigbigbox" v-show="copyflag" style="margin-left: -10px;">
          <el-form-item label="">
            <el-radio-group v-model="permission.copyPaste.enable" @change="copyvalue">
              <p style="margin-bottom: 10px; padding-left: 45px">
                <el-radio :label="1" @click.native.prevent="checkboxRadio7(1)" :disabled="copydisable">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.PreventTheCurrentApplicationDataFromBeingCopiedAndPastedToUnprotectedApplications')}}</el-radio>
              </p>
              <p style="padding-left: 45px">
                <el-radio :label="2" @click.native.prevent="checkboxRadio7(2)" :disabled="copydisable">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.TheCurrentApplicationDataCannotBeCopiedAndPastedToAnyOtherApplication')}}</el-radio>
              </p>
            </el-radio-group>
            <p>
              <el-checkbox v-model="permission.copyPaste.collect" @change="copyvalue" true-label="16" false-label="0" :disabled="copychecked" style="padding-left: 45px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CollectionBehavior')}}</el-checkbox>
            </p>
          </el-form-item>
          <el-form-item style="padding-left: 43px; margin-top: -24px">
            <p style="
                margin-bottom: -10px;
                margin-top: 10px;
                color: #606266;
                font-size: 14px;
              ">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ResponseAction')}}
            </p>
            <el-radio-group v-model="permission.copyPaste.action" @change="copyvalue">
              <el-radio :label="4" @click.native.prevent="checkboxRadio8(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AlarmMessage')}}</el-radio>
              <el-radio :label="8" @click.native.prevent="checkboxRadio8(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearData')}}</el-radio>
              <el-radio :label="2" @click.native.prevent="checkboxRadio8(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ExitApplication')}}</el-radio>
              <el-radio :label="64" @click.native.prevent="checkboxRadio8(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DenyAccess')}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearDates')">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearDateContent')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>

          <el-switch @change="clearDataMethods" active-value="1" inactive-value="0" v-model="permission.clearData.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>
        <div class="bigbigbox" v-show="clearBox" style="margin-left: 104px">
          <el-form label-width="100px">
            <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.RegularRemoval')" style="margin-left: 25px">
              <div style="display: flex">
                <span style="margin-right: 11px">
                  <el-checkbox :disabled="allFile" true-label="1" false-label="0" @change="allMethods" v-model="permission.clearData.timeUse">
                  </el-checkbox>
                </span>
                <!-- oninput="value=value.replace(/[^\d.]/g,'')" -->
                <span style="margin-right: 11px">
                  <el-input style="width: 80px" maxlength="3" size="mini" v-model="permission.clearData.time" @input="changeInput" oninput="value=value.replace(/^(0+)|[^\d]/g,'')">
                  </el-input>
                  <!-- 原来的限制特殊字符 oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" -->
                </span>
                <span>
                  <el-select style="width: 120px" v-model="permission.clearData.timeUnit" @change="changeSelet" size="mini" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="$t(`AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.timeStatus.${item.label}`)" :value="item.value">
                    </el-option>
                  </el-select>
                </span>
              </div>
            </el-form-item>
            <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileType')" required style="margin-left: 25px">
              <div style="display: flex;">
                <el-radio-group style="line-height: 39px;" v-model="permission.clearData.fileTypes[0]" @change="changeAll">
                  <el-radio @change="changeAll1(0)" :label="0">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AllFile')}}</el-radio>
                  <el-radio @change="changeAll1(1)" :label="1" style="margin-left: 15px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Custom')}}</el-radio>
                  <!-- onkeyup="this.value=this.value.replace(/[^a-zA-Z]/g,'')" -->
                  <!-- \a-\z\A-\Z0-9\u4E00-\u9FA5\@\. -->
                  <el-input :disabled="diEr" @change="inputQin(permission.clearData.abcd)" style="width: 120px" @input="(e) => (permission.clearData.abcd = Geography(e))" maxlength="100" size="mini" v-model="permission.clearData.abcd"></el-input>
                </el-radio-group>

              </div>
            </el-form-item>
            <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.FileName')" style="margin-left: 25px">
              <span style="color: #606266; margin-right: 6px">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.contain')}}</span>
              <el-input style="width: 120px" size="mini" :maxlength="100" v-model="permission.clearData.fileNames" @input="changeInput"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ElectronicDFence')">
          <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
            <div slot="content" style="color: #807e7e">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.PreventSensitiveDataLeakageWhenSandboxApplicationsAreUsedOoutsideTheFence')}}
            </div>
            <img src="../../../../assets/question.png" alt="" srcset="" />
          </el-tooltip>

          <el-switch @change="electronic" active-value="1" inactive-value="0" v-model="permission.electronFences.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
        </el-form-item>
        <div v-show="weiLan" style="margin-left: 159px; margin-bottom: 86px">
          <!-- <el-checkbox
            true-label="out"
            false-label="in"
            @change="systemMethods"
            v-model="permission.electronFences.scope"
            :disabled="weiLanDisable"
          >
            应用在地理围栏外使用
          </el-checkbox> -->
          <div style="margin-left: -10px;display:flex;">
                <div style="width:295px; margin: 0px 9px 0px 0px;display: flex;">
                  <el-table style="width: 100%" ref="diLiMultipleTable" :data="tableOutside" stripe size="mini" tooltip-effect="dark" class="tableClass" :header-cell-style="{ background: '#f7f8fa' }">
                    <el-table-column header-align="left" align="left" prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Names')" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column header-align="left" align="left" prop="" :label="$t('public.Controls')" show-overflow-tooltip>
                      <template v-slot="{ row }">
                        <img @click="delapp(row)" src="@/images/icon_del.png" alt="" style="  width: 15px;
                          height: 15px;
                          margin-left: 5px;
                          cursor: pointer;
                        " />
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div>
                  <el-button style="margin-top:-9px;" size="mini" type="primary" @click="geographic" icon="el-icon-plus">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.geoFencings')}}</el-button>
                </div>
          </div>
          <div style="margin-bottom: 10px;margin-left: -5px;">
            <el-checkbox true-label="16" false-label="0" v-model="permission.electronFences.collect">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.CollectionBehavior')}}</el-checkbox>
          </div>
          <div  style="    margin-left: -7px;">
            <p  style="margin-bottom: 10px; color: #606266; font-size: 14px">
              {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ResponseAction')}}
            </p>
            <el-radio-group v-model="permission.electronFences.action">
              <el-radio :label="4" @click.native.prevent="checkboxRadio10(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.AlarmMessage')}}</el-radio>
              <el-radio :label="8" @click.native.prevent="checkboxRadio10(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ClearData')}}</el-radio>
              <el-radio :label="2" @click.native.prevent="checkboxRadio10(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.ExitApplication')}}</el-radio>
              <el-radio :label="64" @click.native.prevent="checkboxRadio10(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.DenyAccess')}}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-form>
    </div>
    <Eldialog :num="'470px'" :title="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.pleaseTitle')" :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true" :dialogVisible="statusVisible" @handleClose="handleClose" @heightCustom="heightCustom" :determineBtn="true" @determine="determine">
      <el-input v-model="input" @keyup.enter.native="onSbumit" size="small" style="width: 180px; margin-bottom: 10px" :placeholder="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.pleaseInput')"></el-input>
      <el-button type="primary" @click="onSbumit" size="small" style="margin-left: 3px">{{$t('public.Inquire')}}</el-button>
      <el-table size="mini" stripe ref="multipleTable" :data="tableDataNmae" class="tableClass" tooltip-effect="dark" style="width: 97%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{
          prop: 'updateTime',
          order: 'descending',
        }" @sort-change="sort">
        <el-table-column header-align="center" align="center" width="30">
          <template v-slot="{ row }">
            <el-radio-group @change="changeEvent(row)" v-model="radio">
              <el-radio :label="row.id">{{}}</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Names')" width="150">
          <template slot-scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="groupFullName" :label="$t('public.Section')" width="150" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" :label="$t('public.ModificationTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.updateTime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; width: 565px; justify-content: space-between">
        <p style="margin-top: 15px">
          {{$t('public.total')}}<b>{{ this.dataTolCount }}</b>{{$t('public.recordss')}}
        </p>
        <p>
          <!-- <el-button
            style="marginleft: 50px; margin-top: 10px"
            type="primary"
            size="small"
            @click="joinType"
            >加入</el-button
          > -->
        </p>
      </div>
    </Eldialog>
    <!-- 图片分享 加水印 弹框界面水印 -->
    <Eldialog :num="'470px'" :title="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.pleaseTitle')" :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true" :dialogVisible="statusVisibleShare" @handleClose="handleCloseShare" @heightCustom="heightCustom" :determineBtn="true" @determine="shareDetermine">
      <el-input v-model="iptShare" @keyup.enter.native="onSearch" size="small" style="width: 180px; margin-bottom: 10px" :placeholder="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.pleaseInput')"></el-input>
      <el-button type="primary" @click="onSearch" size="small" style="margin-left: 3px">{{$t('public.Inquire')}}</el-button>
      <el-table size="mini" stripe ref="sharemultipleTable" :data="imgNeiTable" class="tableClass" tooltip-effect="dark" style="width: 97%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{
          prop: 'updateTime',
          order: 'descending',
        }" @sort-change="sort">
        <el-table-column header-align="center" align="center" width="30">
          <template v-slot="{ row }">
            <el-radio-group @change="changeEventTwoId(row)" v-model="twoRadioWaterId">
              <el-radio :label="row.id">{{}}</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Names')" width="150">
          <template slot-scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="groupFullName" :label="$t('public.Section')" width="150" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" :label="$t('public.ModificationTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.updateTime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; width: 565px; justify-content: space-between">
        <p style="margin-top: 15px">
          {{$t('public.total')}}<b>{{ this.sumCount }}</b>{{$t('public.recordss')}}
        </p>
        <p>
          <!-- <el-button
            style="marginleft: 50px; margin-top: 10px"
            type="primary"
            size="small"
            @click="joinType"
            >加入</el-button
          > -->
        </p>
      </div>
    </Eldialog>
    <!-- 电子围栏 弹框 -->
    <Eldialog :num="'470px'" :width="'1000px'" :title="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.SelectGeoFencing')" :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true" :dialogVisible="diLi" @handleClose="handleCloseDiLi" @heightCustom="heightCustom" :determineBtn="true" @determine="diLiDetermine">
      <el-table size="mini" stripe ref="diLiMultipleTable" :data="tableInner" :row-key="getRowKeys" class="tableClass" tooltip-effect="dark" style="width:97%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{
          prop: 'updateTime',
          order: 'descending',
        }" @sort-change="sort" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" :reserve-selection="true">
        </el-table-column>
        <el-table-column prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Names')" width="100" :show-overflow-tooltip="true" sortable="custom">
        </el-table-column>
        <el-table-column prop="groupFullName" :label="$t('public.Section')" width="80" :show-overflow-tooltip="true" sortable="custom">
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">{{
              row.groupFullName.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="longitude" :label="$t('public.LatitudeLongitude')" width="120" :show-overflow-tooltip="true" sortable="custom">
        </el-table-column>
        <el-table-column prop="shape" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.graph')" width="120" sortable="custom">
          <template v-slot="{ row }">
            <template v-if="row.shape == 0"> {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.roundness')}} </template>
            <template v-if="row.shape == 1"> {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.rectangle')}} </template>
          </template>
        </el-table-column>
        <el-table-column prop="radius" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.Radius')" width="120" sortable="custom">
          <template v-slot="{ row }">
            <span v-if="row.shape == 0"> {{ row.radius }}{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.meter')}} </span>
            <span v-if="row.shape == 1">
              {{ row.length }},{{ row.width }}{{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.meter')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="scope" :label="$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.InsideAndOut')" width="120" sortable="custom">
          <template v-slot="{ row }">
            <template v-if="row.scope == 0"> {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.interior')}} </template>
            <template v-if="row.scope == 1"> {{$t('AppManage.ApplicationStrategy.CurrentPage.ProtectionDataTab.interior')}} </template>
          </template>
        </el-table-column>
        <el-table-column prop="lastUpdateTime" :label="$t('public.ModificationTime')" :show-overflow-tooltip="true" sortable="custom">
        </el-table-column>
      </el-table>
      <div style="display: flex; width: 565px; justify-content: space-between">
        <p style="margin-top: 15px">
          {{$t('public.total')}}<b>{{ this.page.total }}</b>{{$t('public.recordss')}}
        </p>
        <p>
          <!-- <el-button
            style="marginleft: 50px; margin-top: 10px"
            type="primary"
            size="small"
            @click="joinType"
            >加入</el-button
          > -->
        </p>
      </div>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from '@/components/elDialog' //详情弹框
export default {
  components: {
    Eldialog
  },
  props: {
    dataProtection: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    osTypeId: {
      type: Number,
      default: ''
    }
  },
  data() {
    return {
      waterId: [], //id
      groupFullNameShowByIndex: '', //用户部门截取的下标
      waterData: [],
      permission: {
        watermark: {
          use: '0',
          type:'2',
          // bright: '0', //明水印 0-关闭 1-开启
          // dark: '0', //暗水印 0-关闭 1-开启
          waters: []
        },
        encryptAndDecrypt: {
          use: '0'
        },
        // 安全输入
        safeInput: {
          use: '0',
          //新增加 采集动动
          safeKeyboard: '0', //安全键盘
          systemInput: '0', //仅系统输入法
          collect: '0', //3选1
          action: '0' //采集动动
        },
        // 截屏/录屏保护
        screen: {
          use: '0',
          enable: '0',
          collect: '0', //采集行为 、采集行为并上传信息 都是 16   但upload  前者是0  后者是1
          upload: '2', //未勾选 0 勾选 1  //采集行为并上传截屏信息 （16  1）
          action: '0'
        },
        // 系统分享
        sysShare: {
          use: '0',
          abcd: '', //自定义输入  特殊字符限制
          shareType:"0", //仅作 前端 回显 区分 第一个单选还是第二个
          forbidShare: {
            use: '0', //禁止分享 二选一 选中都为 1 ，  
            fileNames: '', //禁止分享 下文件名称 传值字符串以","分隔 英文逗号分隔
            fileTypes: [0, '*'], //禁止分享下选择的文件类型 传值为["*","doc,docx","xls,xlsx"]
             
          },
          abcd2: '', //自定义输入  特殊字符限制
          forbidUnShare: {
            use: '0', //禁止分享 二选一 选中都为 1 ，  
            fileNames: '', //禁止分享 下文件名称 传值字符串以","分隔 英文逗号分隔
            fileTypes: [0, '*'], //禁止分享下选择的文件类型 传值为["*","doc,docx","xls,xlsx"]
             
          },
          // "*",
          // "doc,docx",
          // "xls,xlsx",
          // "ppt,pptx",
          // "pdf",
          // "txt",
          // "png",
          // "jpg"
          fileShareEncrypt: {
            //文件分享加密 0-关闭 1-开启
            use: '0'
          },
          imgShareWatermark: {
            //图片分享加水印  0-关闭 1-开启
            use: '0',
            waters: []
          },
          collect: '0', //采集行为
          action: '0' //响应动作
          // enable: '0',
          // collect: '0',
          // action: '0'
        },

        // 清除数据
        clearData: {
          //清除数据 0-开启 1-关闭
          use: '0',
          abcd: '', //自定义输入  特殊字符限制
          timeUse: '0', //定期清除开关
          time: '20', //定期清除时间
          timeUnit: '1', //定期清除时间单位 1-小时 2-天
          fileTypes: [0, '*'], //定期清除文件类型 传值["*","db","xml"]
          fileNames: '' //'app,temp'定时清除文件名称 传值字符串以","分隔  英文逗号分隔
        },
        //电子围栏
        // electronic: {
        //   // 应用在地理围栏外使用：默认勾选后，且不可取消
        //   mandatory: "1",
        //   //清除数据 0-开启 1-关闭
        //   use: "0",
        //   diliweilan: {
        //     ids: [],
        //   },
        // },
        //电子围栏
        electronFences: {
          //清除数据 0-开启 1-关闭
          use: '0',
          // 应用在地理围栏外使用：默认勾选后，且不可取消
          scope: 'out', //in 内 out 应用围栏外使用
          geofencesIds: [], //围栏Id
          action: '0', //响应动作 4 告警提示 2 退出应用  8 清除数据
          collect: '0' //采集行为 0 未勾选 16 勾选
        },
        // 赋值粘贴
        copyPaste: {
          use: '0',
          enable: '0',
          collect: '0',
          action: '0'
        }
      },
      //6.0.0建设银行
      // 界面水印
      checkedBright: false, //明水印选中 禁用
      checkedDark: false, //暗水印选中 禁用
      waterListK: false, //界面水印列表默认隐藏
      // 安全输入
      checkedInput: false, //安全输入
      checkedSystem: false, //仅系统输入法
      checkedAction: false, //采集行为
      checkedBox: false, //安全输入Switch不开启  box内容不显示
      // 系统分享
      diYi: true,
      diYi2:true,
      shareBox: false, //系统分享Switch不开启  box内容不显示
      shareFlag: false, //禁止分享
      /*文件类型*/
      allFile: false, //所有文件
      docFile: false, //doc/docx
      xlsFile: false, //xls/xlsx
      pptFile: false, //ppt/pptx
      pdfFile: false, //pdf
      txtFile: false, //txt
      pngFile: false, //png
      jpgFile: false, //jpg
      shareFile: false, //文件分享加解密
      shareImg: false, //图片分享加水印
      shareCai: false, //采集行为
      allCheck: '',
      docCheck: '',
      xlsCheck: '',
      pptCheck: '',
      pdfCheck: '',
      txtCheck: '',
      pngCheck: '',
      jpgCheck: '',
      fileTypeFlag: false, //文件类型 复选框不选中  box内容不显示
      fileTypeFlag2: false, //文件类型 复选框不选中  box内容不显示
      // 图片分享加水印
      imageShareFlag: false, //图片分享加水印  复选框不选中 box内容不显示
      imgTableData: [], //图片分享 外层 列表
      twoWater: [], //的哥个界面水印存放的id
      twoRadioWaterId: '', //界面水印单选的id
      imgNeiTable: [], //图片方分享 弹框内 列表
      sumCount: 0, //总数
      statusVisibleShare: false, //弹框 默认不展示
      listData: {
        type: '20',
        action: 'edit',
        currentPage: 1, //pageNumber
        pageSize: 999, //rowNumber
        // where: { name: "" },
        // sort: "updateTime desc",
        orderColume: 'updateTime',
        orderRule: 'DESC',
        name: ''
      },
      iptShare: '', //搜索
      // 清除数据
      // 小时  天
      options: [
        {
          value: '1',
          label: '小时'
        },
        {
          value: '2',
          label: '天'
        },
        {
          value: '3',
          label: '分钟'
        }
      ],
      diEr: true,
      value: '1', //默认是小时
      clearAllFile: false, //所有文件 复选框
      clearDbFile: false, //db 文件  复选框
      clearXmlFile: false, //xml 文件 复选框
      AllModel: '', //绑定  所有文件
      dbModel: '', //绑定db 文件
      xmlModel: '', //绑定 xml 文件
      clearBox: false, //清除数据Switch不开启  下面Box默认隐藏
      // 电子围栏
      weiLan: false, //电子围栏  默认不展示
      weiLanDisable: true, //应用在地理围栏外使用  默认禁用
      diLi: false, //弹框默认不展示
      tableInner: [], //表格 内层接收数据
      // 地理围栏参数
      page: {
        total: 0, //总数
        currentPage: 1, //当前页
        pageSize: 999, //每页显示条数
        orderColume: 'lastUpdateTime', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      tableOutside: [], //表格 外层接收数据
      idsDiLi: [], //地理围栏存放的id
      diLieSelection: [], //多选 element ref
      diGeoFences: [], //地理围栏 外层的id
      //------------
      radio4: '1',
      radio: '',
      radio1: '', //单选
      input: '', //弹框查询
      dataTolCount: 0, //初始化总数为0
      statusVisible: false, //点击相应动作弹出详情框
      formflag: false, //界面水印是否开关
      jietu: false, //开关是否显示（截图/录屏）
      sysflag: false, //开关是否显示（系统分享
      copyflag: false, //开关是否显示(复制/粘贴)
      copydisable: false, //开关复制粘贴的 单选
      checked1: '', //多选1
      checked2: '', //多选2
      checked: false,
      copychecked: false,
      spanflag: false, //多选1选中后面提示语显示
      more1: '', //多选（系统分享
      more2: '', //多选（系统分享
      form: [
        {
          delivery1: false,
          delivery2: false,
          delivery3: false,
          delivery4: false,
          delivery5: false,
          delivery6: false
        }
      ],
      dictData: [],
      dictDataItem: null,
      tableDataNmae: [], //列表界面水印响应数组
      responsedata: {
        type: '20',
        action: 'edit',
        currentPage: 1, //pageNumber
        pageSize: 999, //rowNumber
        // where: { name: "" },
        // sort: "updateTime desc",
        orderColume: 'updateTime',
        orderRule: 'DESC',
        name: ''
      },

      // 截屏保护  数组渲染
      Screen: [
        {
          enable: '0',
          collect: '0',
          action: '0'
        }
      ],

      // 截屏/录屏的disabled
      disabled: false,
      listData: {
        type: '20',
        action: 'edit',
        pageSize: 10,
        pageNumber: 1,
        rowNumber: 100000,
        where: {
          name: ''
        },
        sort: 'updateTime desc'
      }
    }
  },
  created() {
    
    // if(this.dataProtection.appPermission.permission.watermark.waters){
    //     this.waterId =
    //     this.dataProtection.appPermission.permission.watermark.waters[0].id; 
    //   }  
      if(this.dataProtection.appPermission.permission.sysShare.imgShareWatermark.waters){
        this.waterIdEr =
      this.dataProtection.appPermission.permission.sysShare.imgShareWatermark.waters[0].id; 
      }
  },
  mounted() {
    console.log(this.osTypeId,'传参----')
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.geographyData() //调用 地理围栏列表
    setTimeout(() => {
      this.callBack()
      this.theResponse2()
      this.imgTable()
    }, 500)
  },
  methods: {
    //限制输入特殊字符
    // keyUpDi(e) {
    //        e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
    //       },

    callBack() {
      if (this.dataProtection) {
        if (
          this.dataProtection.appPermission.permission.sysShare
            .imgShareWatermark.use == 1
        ) {
          this.shareImage(this.dataProtection.appPermission.permission.sysShare
            .imgShareWatermark.use)
          this.imgTableData =
            this.dataProtection.appPermission.permission.sysShare.imgShareWatermark.waters
        }
        this.permission.clearData =
          this.dataProtection.appPermission.permission.clearData
        this.clearDataMethods()
        this.watersList()
        // 电子围栏
        if (
          this.permission.electronFences.use !=
          this.dataProtection.appPermission.permission.electronFences.use
        ) {
          this.permission.electronFences =
            this.dataProtection.appPermission.permission.electronFences 
          this.tableInner.forEach((item) => {
            this.dataProtection.appPermission.permission.electronFences.geofencesIds.forEach(
              (newItem) => {
                if (item.id == newItem) {
                  this.tableOutside.push({
                    id: item.id,
                    name: item.name
                  })
                }
              }
            )
          })
          this.electronic(this.permission.electronFences.use)
        }
        // 界面水印
        if (
          this.permission.watermark.use !=
          this.dataProtection.appPermission.permission.watermark.use
        ) {
          this.permission.watermark.type =
            this.dataProtection.appPermission.permission.watermark.type
          if (
            this.permission.watermark.type == 1 ||
            this.permission.watermark.type == 0
          ) {
            this.waterListK = true
          }
          // this.dataProtection.appPermission.permission.watermark.waters
          this.dictData = []
          this.dataProtection.appPermission.permission.watermark.waters.forEach(
            (item) => {
              this.dictData.push({ name: item.name })
            }
          )
          this.waterData = this.dataProtection.appPermission.permission.watermark.waters[0]
          this.waterId = this.dataProtection.appPermission.permission.watermark.waters[0].id; 
          //  this.permission.watermark.waters=this.dataProtection.appPermission.permission.watermark.waters
          this.permission.watermark.waters =
            this.dataProtection.appPermission.permission.watermark.waters

          this.permission.watermark.use =
            this.dataProtection.appPermission.permission.watermark.use
          this.test(this.permission.watermark.use)

          // this.joinType()
        }
        // 文件透明加解密
        if (
          this.permission.encryptAndDecrypt !=
          this.dataProtection.appPermission.permission.encryptAndDecrypt
        ) {
          this.permission.encryptAndDecrypt =
            this.dataProtection.appPermission.permission.encryptAndDecrypt
          this.permission.encryptAndDecrypt.use =
            this.dataProtection.appPermission.permission.encryptAndDecrypt.use
          this.test2(this.permission.encryptAndDecrypt.use)
        }
        // 安全键盘
        if (
          this.permission.safeInput !=
          this.dataProtection.appPermission.permission.safeInput
        ) {
          this.permission.safeInput =
            this.dataProtection.appPermission.permission.safeInput
          this.permission.safeInput.use =
            this.dataProtection.appPermission.permission.safeInput.use
          this.test3(this.permission.safeInput.use)
        }
        // 截屏/录屏保护
        if (
          this.permission.screen !=
          this.dataProtection.appPermission.permission.screen
        ) {
          this.permission.screen =
            this.dataProtection.appPermission.permission.screen
          this.permission.screen.use =
            this.dataProtection.appPermission.permission.screen.use
          this.testjt(this.permission.screen.use)
        }
        // 系统分享
        if (
          this.permission.sysShare !=
          this.dataProtection.appPermission.permission.sysShare
        ) {
          this.permission.sysShare =
            this.dataProtection.appPermission.permission.sysShare 
          this.permission.sysShare.use =
            this.dataProtection.appPermission.permission.sysShare.use
          // 回显 禁止分享文件到无防护应用/禁止当前应用文件分 shareType 回显 
          this.permission.sysShare.shareType = this.dataProtection.appPermission.permission.sysShare.shareType 
          this.permission.sysShare.forbidShare = this.dataProtection.appPermission.permission.sysShare.forbidShare
          this.permission.sysShare.forbidUnShare = this.dataProtection.appPermission.permission.sysShare.forbidUnShare 
          if(this.dataProtection.appPermission.permission.sysShare.forbidUnShare.fileTypes[0] ==1){ 
            this.diYi = false 
             console.log(this.diYi,'当前自定义2的状态')
            } 
          if(this.dataProtection.appPermission.permission.sysShare.forbidShare.fileTypes[0] ==1){ 
            this.diYi2 = false 
            console.log( this.diYi2,'当前自定义1的状态')
          }
          if (this.permission.sysShare.shareType == '1') { 
              this.fileTypeFlag = true
              this.fileTypeFlag2 = false 
            }else if(this.permission.sysShare.shareType == '2'){  
              this.fileTypeFlag2 = true
              this.fileTypeFlag = false
            } else{   
              this.fileTypeFlag = false
              this.fileTypeFlag2 = false
            }
          if (this.permission.sysShare.imgShareWatermark.use == 1) {
            this.shareImage()
            this.imgTableData =
              this.permission.sysShare.imgShareWatermark.waters
              this.imgTable()
          }
            
          if (this.permission.sysShare.imgShareWatermark.use == 1) {
            this.imageShareFlag = true //图片分享 表格打开
          }
          this.testsystem(this.permission.sysShare.use)
          // if (this.permission.sysShare.forbidShare.use == 1) {
          //   this.shareMethods(this.permission.sysShare.forbidShare.use)
          // }
        }

        // 复制、粘贴保护
        if (
          this.permission.copyPaste !=
          this.dataProtection.appPermission.permission.copyPaste
        ) {
          this.permission.copyPaste =
            this.dataProtection.appPermission.permission.copyPaste
          this.permission.copyPaste.use =
            this.dataProtection.appPermission.permission.copyPaste.use
          this.copy(this.permission.copyPaste.use)
        }
        if (this.permission.clearData.fileTypes[0] == 1) {
          this.diEr = false
        }
       
        // this.changeAll1(this.permission.clearData.fileTypes[0])
        // this.allfileTypes(this.permission.sysShare.forbidShare.fileTypes[0])
        // this.test()
        // this.test2()
        // this.test3()
        // this.testjt()
        // this.testsystem()
        // this.morechecked1()
        // this.copy()
        // this.copyvalue()
      }
    },
    // 明水印复选框
    changeEvents(val) {
      // if (val === "1") {
      //   this.permission.watermark.bright = val;
      //   this.waterListK = true; //界面水印列表、按钮 显示
      // } else if (val === "0" && this.permission.watermark.dark == "0") {
      //   this.waterListK = false; //界面水印列表、按钮 隐藏
      //   this.permission.watermark.bright = val;
      // }
      this.$emit('water', this.permission)
    },
    // 安全键盘 复选框
    safeKeyMethods(val) {
      this.$emit('water', this.permission)
    },
    // 仅系统输入法 复选框
    systemMethods(val) {
      this.$emit('water', this.permission)
    },
    // 采集行为 复选框
    actionMethods(val) {
      this.$emit('water', this.permission)
    },
    // 安全输入 3选一  单选
    safeKeyRadios(val) {
      this.$emit('water', this.permission)
    },
    // 禁止分享
    shareMethods(val) {  
      this.permission.sysShare.shareType =
        val == this.permission.sysShare.shareType ? '0' : val  
       
      if (this.permission.sysShare.shareType == '1') { 
        // this.permission.sysShare.forbidShare.use = val
        // this.permission.sysShare.forbidShare.fileTypes = ['*']
        this.permission.sysShare.forbidUnShare.use = '1'
        // 二选一 选中第二个单选就把第一个 置空
        this.permission.sysShare.abcd = ''
        this.permission.sysShare.forbidShare.use = '0'
        this.permission.sysShare.forbidShare.fileNames = ''
        this.permission.sysShare.forbidShare.fileTypes = [0, '*']
        this.fileTypeFlag = true
        this.fileTypeFlag2 = false 
        this.allfileTypes(0)
        
      }else if(this.permission.sysShare.shareType == '2'){  
     
        this.permission.sysShare.forbidShare.use = '1'
        // 二选一 选中第一个单选就把第二个 置空
        this.permission.sysShare.abcd2 = ''
        this.permission.sysShare.forbidUnShare.use = '0'
        this.permission.sysShare.forbidUnShare.fileNames = ''
        this.permission.sysShare.forbidUnShare.fileTypes = [0, '*']
        this.fileTypeFlag2 = true
        this.fileTypeFlag = false 
        this.allfileTypes2(0)
      } else if(this.permission.sysShare.shareType == '0'){  
        this.permission.sysShare.forbidShare.use = '0' 
        this.permission.sysShare.forbidUnShare.use = '0' 
        this.permission.sysShare.forbidShare.fileNames = ''
        this.permission.sysShare.forbidShare.fileTypes = [0, '*']
        this.permission.sysShare.forbidUnShare.fileNames = ''
        this.permission.sysShare.forbidUnShare.fileTypes = [0, '*']
        this.fileTypeFlag = false
        this.fileTypeFlag2 = false
      } 
     
      if(this.permission.sysShare.forbidUnShare.use == 1 && this.permission.sysShare.forbidShar.use == 0){
        this.allfileTypes(0)
      } 
      if(this.permission.sysShare.forbidUnShare.use == 0 && this.permission.sysShare.forbidShare.use == 1){
        this.allfileTypes2(0)
      }
    },
    allMethods(val) {
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  所有文件
    allfileTypes(val) { 
      console.log(val,'valdangqiand当前点击');
      if (val == 0) {
        this.diYi = true
        this.permission.sysShare.forbidUnShare.fileTypes = [0, '*'] 
        this.permission.sysShare.abcd2 = '' 
      } else if (val == 1) {
        this.permission.sysShare.forbidUnShare.fileTypes = [1, ''] 
        this.diYi = false
        if (
          this.permission.sysShare.forbidUnShare.fileTypes[1] == '*' &&
          this.permission.sysShare.forbidUnShare.fileTypes[1].length == 1
        ) {
          this.permission.sysShare.forbidUnShare.fileTypes = [1, '']
        }
      }
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  所有文件
    allfileTypes2(val) { 
      if (val == 0) {
        this.diYi2 = true 
        this.permission.sysShare.forbidShare.fileTypes = [0, '*']
        this.permission.sysShare.abcd = '' 
      } else if (val == 1) { 
        this.permission.sysShare.forbidShare.fileTypes = [1, '']
        this.diYi2 = false
        if (
          this.permission.sysShare.forbidShare.fileTypes[1] == '*' &&
          this.permission.sysShare.forbidShare.fileTypes[1].length == 1
        ) {
          this.permission.sysShare.forbidShare.fileTypes = [1, '']
        }
      }
      this.$emit('water', this.permission)
    },
    inputXix(val) {
      if (val) {
         this.permission.sysShare.forbidUnShare.fileTypes[1] =
          this.permission.sysShare.abcd2.replace(/，/, ',')
      }
      if (val == '') {
        this.permission.sysShare.forbidUnShare.fileTypes[1] = ''
      }
      this.$emit('water', this.permission)
    },
    inputXix2(val) {
      if (val) {
        this.permission.sysShare.forbidShare.fileTypes[1] =
          this.permission.sysShare.abcd.replace(/，/, ',')
      }
      if (val == '') {
        this.permission.sysShare.forbidShare.fileTypes[1] = ''
      }
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  doc文件
    docMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  xls文件
    xlsMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  ppt文件
    pptMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  pdf文件
    pdfMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  txt文件
    txtMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  png文件
    pngMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 禁止分享下的  jpg文件
    jpMethods(val) {
      this.permission.sysShare.forbidShare.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.sysShare.forbidShare.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 文件分享加解密
    shareWen(val) {
      // console.log(val, "复选框值");
    },
    // 图片分享加水印
    shareImage(val) {
      // console.log(val, "复选框值");
      if (val === '1') {
        this.imageShareFlag = true // 图片分享  界面水印 table打开
      } else {
        this.imageShareFlag = false // 图片分享  界面水印 table关闭
        // this.permission.sysShare.imgShareWatermark.waters=[]

      }
      this.$emit('water', this.permission)
    },
    // 采集行为
    shareBehavior(val) {
      // console.log(val, "复选框值");
    },
    //
    watersList() {
      this.permission.watermark.waters =
        this.dataProtection.appPermission.permission.watermark.waters
      this.$emit('water', this.permission)
    },
    // 排序
    sort(column) {
      if (column.prop == 'name') {
        if (column.order == 'ascending') {
          this.responsedata.orderColume = 'name'
          this.responsedata.orderRule = 'ASC'
        } else {
          this.responsedata.orderColume = 'name'
          this.responsedata.orderRule = 'DESC'
        }
      } else if (column.prop == 'groupFullName') {
        if (column.order == 'ascending') {
          this.responsedata.orderColume = 'groupFullName'
          this.responsedata.orderRule = 'ASC'
        } else {
          this.responsedata.orderColume = 'groupFullName'
          this.responsedata.orderRule = 'DESC'
        }
      } else if (column.prop == 'updateTime') {
        if (column.order == 'ascending') {
          this.responsedata.orderColume = 'updateTime'
          this.responsedata.orderRule = 'ASC'
        } else {
          this.responsedata.orderColume = 'updateTime'
          this.responsedata.orderRule = 'DESC'
        }
      }
      this.theResponse()
    },
    checkboxRadio1(value) {
      this.permission.screen.enable =
        value == this.permission.screen.enable ? '' : value
      if (this.permission.screen.enable == 1) {
        this.permission.screen.collect = ''
      }
    },
    IosCheckboxRadio2(value){ 
      console.log(value,'value,ios enabel')
      this.permission.screen.enable = value
      // if (
      //   this.permission.screen.upload == '0' ||
      //   this.permission.screen.upload == '1'
      // ) {
      //   this.permission.screen.collect = '16'
      // } else {
      //   this.permission.screen.collect = '0'
      //   this.permission.screen.upload == '2'
      // }
      // console.log(this.permission.screen.enable,'this.permission.screen.enable')
      // console.log(this.permission.screen.upload,'this.permission.screen.upload')
      // console.log(permission.screen.collect,'permission.screen.collect')

      this.$emit('water', this.permission)
    },
    checkboxRadio2(value) { 
      console.log(value,'vlaue嘎嘎嘎嘎嘎');
      this.permission.screen.upload =
        value == this.permission.screen.upload ? '2' : value 
        //ios三选二或则全选（除采集行为2选一其余都不做限制）
      if (
        this.permission.screen.upload == '0' ||
        this.permission.screen.upload == '1'
      ) {
        //禁止系统对此应用的截屏/录屏操作 enable: 选中是1  未选中0
        //采集行为 upload:0 collect:16  采集行为并上传截屏信息 upload1  collect:16
        //响应动作 action ：4、8、2、64
        if(this.type == 'android') {
          this.permission.screen.enable ="0"
          this.permission.screen.collect = '16'
        }else {
          this.permission.screen.collect = '16'
        }
      } 
      else {
        this.permission.screen.collect = '0'
        this.permission.screen.upload == '2'
      }
       //Android 3选一  禁止不可选中采集行为和响应动作哦
      if(this.osTypeId == 20){
        if (this.permission.screen.upload == 3) {
          this.permission.screen.enable = 1
        } 
         if (this.permission.screen.upload == 2) {
            this.permission.screen.enable = 0
            console.log(this.permission.screen.enable,'2020202020');
          } 
        if((this.permission.screen.upload==3 ||this.permission.screen.upload==0 ||this.permission.screen.upload==1)&&  (this.permission.screen.enable==1  && this.permission.screen.upload ==3)){
          this.permission.screen.action = 0
        }
      }  

      this.$emit('water', this.permission) 
    },
    checkboxRadio3(value) {
      console.log(value,this.permission.screen.action,'------')
      this.permission.screen.action =
        value == this.permission.screen.action ? '' : value
        if(this.osTypeId == 20){
         
          if((this.permission.screen.upload==3 ||this.permission.screen.upload==0 ||this.permission.screen.upload==1)&&  (this.permission.screen.enable==1  && this.permission.screen.upload ==3)){
            this.permission.screen.upload = 2
            if (this.permission.screen.upload == 2) {
            this.permission.screen.enable = 0 
          } 
          }
        }
        this.$emit('water', this.permission) 
    },
    checkboxRadio6(value) {
      this.permission.sysShare.action =
        value == this.permission.sysShare.action ? '' : value
    },

    checkboxRadio7(value) {
      if (!this.copydisable) {
        this.permission.copyPaste.enable =
          value == this.permission.copyPaste.enable ? '' : value
      }
    },
    checkboxRadio8(value) {
      this.permission.copyPaste.action =
        value == this.permission.copyPaste.action ? '' : value
    },
    // 安全输入 采集行为  单选 3选1
    checkboxRadio9(value) {
      // console.log(value, "-----安全输入单选");
      this.permission.safeInput.action =
        value == this.permission.safeInput.action ? '' : value
    },
    // 地理围栏 响应动作  单选 3选1
    checkboxRadio10(value) {
      // console.log(value, "-----安全输入单选");
      this.permission.electronFences.action =
        value == this.permission.electronFences.action ? '' : value
    },
    // 系统分享 禁止分享文件到无防护应用  单选 及显示下面 禁用
    checkboxRadio11(value) { 
      this.permission.sysShare.forbidShare.action =
        value == this.permission.sysShare.forbidShare.action ? '' : value
        if(this.permission.sysShare.forbidShare.action == '1'){
          this.fileTypeFlag = true
          this.fileTypeFlag2 = false
        } else if(this.permission.sysShare.forbidShare.action == '2'){
          this.fileTypeFlag2 = true
          this.fileTypeFlag = false
        }else {
          this.fileTypeFlag = false
          this.fileTypeFlag2 = false
        }
    },
    // 系统分享 响应动作  单选 3选1
    // checkboxRadio12(value) {
    //   console.log(value, "禁止分享");
    //   this.permission.sysShare.forbidShare.action2 =
    //     value == this.permission.sysShare.forbidShare.action2 ? '' : value
    //     if(this.permission.sysShare.forbidShare.action2 == '1'){
    //       this.fileTypeFlag2 = true
    //     }else {
    //       this.fileTypeFlag2 = false
    //     }
    // },
    async onSbumit() {
      var param = {
        currentPage: this.responsedata.currentPage,
        pageSize: this.responsedata.pageSize,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/waterConfig/getDataInfo',
        param,
        true
      )
      this.tableDataNmae = res.data.content //列表数据
      this.dataTolCount = res.data.total //列表总数
    },
    async onSearch() {
      var param = {
        currentPage: this.responsedata.currentPage,
        pageSize: this.responsedata.pageSize,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.iptShare
      }
      const res = await this.$axios.post(
        '/httpServe/waterConfig/getDataInfo',
        param,
        true
      )
      this.imgNeiTable = res.data.content //列表数据
      this.sumCount = res.data.total //列表总数
    },
    // 界面水印的开关
    test(val) {
      if (this.permission.watermark.use == '1') {
        this.formflag = true
        this.waterListK = true //界面水印列表、按钮 显示
        // this.permission.watermark.use ='1'
      } else {
        this.formflag = false
        this.waterListK = false //界面水印列表、按钮 显示
        // this.permission.watermark.use ='0'
        // this.permission.watermark.waters=[]
      }
      // debugger
      // if (this.permission.watermark.use == 1) {
      //   this.formflag = true
      // } else {
      //   this.formflag = false
      //   // this.dictData = []
      // }
      this.$emit('water', this.permission)
    },
    // 界面水印弹框 里面  加入 按钮  点击 加入按钮才加入
    joinType() {
      this.dictData = []
      this.dictData.push({ name: this.waterData.name })
      this.permission.watermark.waters = []
      this.permission.watermark.waters.push({
        id: this.waterData.id,
        name: this.waterData.name
      })
      this.$emit('water', this.permission)
    },
    // 界面水印弹框 里面  加入 按钮  点击 加入按钮才加入
    joinType2() {
      this.imgTableData = []
      this.imgTableData.push({
        id: this.twoWater.id,
        name: this.twoWater.name
      })
      // this.imgTableData.push({ name: this.twoWater.name })
      this.permission.sysShare.imgShareWatermark.waters = []
      this.permission.sysShare.imgShareWatermark.waters.push({
        id: this.twoWater.id,
        name: this.twoWater.name
      })
      this.$emit('water', this.permission)
    },
    delwater() {
      this.permission.watermark.waters = this.dictData = undefined
      this.radio = ''
      this.waterData=[]
      this.$emit('water', this.permission)
    },
    twoDel() {
      this.permission.sysShare.imgShareWatermark.waters = this.imgTableData =
        undefined
      this.twoRadioWaterId =''
      this.twoWater=[] 
      this.$emit('water', this.permission)
    },
    // 界面水印change改变事件
    changeEvent(val) { 
      this.waterData = val
    },
    // 界面水印分享+水印
    changeEventTwoId(val) {
      this.twoWater = val
    },
    // 界面水印的响应动作按钮
    responseBtn() {
      this.statusVisible = true //点击出现详情弹框
      this.theResponse()
    },
    // 响应界面水印--列表
    async theResponse2() {
      var param = {
        ids: [this.waterId + '']
      }
      const res = await this.$axios.post(
        '/httpServe/waterConfig/selectNameList',
        param,
        true
      )
      this.dictData = res.data
      // this.$set(this.dictData,'name')
    },
    // 响应界面水印--列表
    async theResponse() {
      var param = {
        currentPage: this.responsedata.currentPage,
        pageSize: this.responsedata.pageSize,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/waterConfig/getDataInfo',
        param,
        true
      )
      this.tableDataNmae = res.data.content //列表数据
      this.dataTolCount = res.data.total //列表总数
      // this.dictData = []
      if (this.permission.watermark.waters) {
        this.$nextTick(() => {
          this.tableDataNmae.forEach((item, index) => {
            if(this.dictData){
              this.dictData.forEach((i, j) => {
                if (item.name == i.name) {
                  this.radio = item.id
                }
              })
            }
            
          })
        })
      }
    },
    //第二个界面水印的按钮button
    imagesBtn() {
      //弹框打开  调用 界面水印列表
      this.statusVisibleShare = true
      this.imageList()
    },
    // 图片分享加水印--列表
    async imgTable() {
      var param = {
        ids: [this.waterIdEr + ""],
      }; 
      const res = await this.$axios.post(
        "/httpServe/waterConfig/selectNameList",
        param,
        true
      );
      // 图片分享+水印  外层
      this.imgTableData = res.data;
      
    },
    // 图片分享加水印--列表
    async imageList() {
      var param = {
        currentPage: this.responsedata.currentPage,
        pageSize: this.responsedata.pageSize,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.input,
      };
      const res = await this.$axios.post(
        "/httpServe/waterConfig/getDataInfo",
        param,
        true
      );
      this.imgNeiTable = res.data.content; //列表数据
      this.sumCount = res.data.total; //列表总数
      if (this.permission.watermark.waters) {
        this.$nextTick(() => {
          this.imgNeiTable.forEach((item, index) => {
            this.imgTableData.forEach((i, j) => {
              if (item.id == i.id) {
                this.twoRadioWaterId = item.id; 
                this.$refs.sharemultipleTable.toggleRowSelection(item, true);
              }
            });
          });
        });
      }
    },
    // 文件夹解密的开关
    test2(val) {
      this.$emit('water', this.permission)
    },
    // 安全键盘的开关
    test3(val) {
      if (val === '1') {
        this.checkedBox = true
      } else {
        this.checkedBox = false
      }
      this.$emit('water', this.permission)
    },
    // 截图/录屏
    testjt(val) {
      if (this.permission.screen.use == 1) {
        this.jietu = true
      } else {
        this.jietu = false
      }
      this.$emit('water', this.permission)
    },
    determine() {
      if (this.waterData.length == 0) {
        this.$message.error('请选择界面水印')
        return false
      }
      this.joinType()
      this.statusVisible = false
    },
    shareDetermine() {
      if (this.twoWater.length == 0) {
        this.$message.error('请选择界面水印')
        return false
      }
      this.joinType2()
      this.statusVisibleShare = false
    },
    // 截屏录屏保护禁止的时候下面3个也禁止掉 开启开关1
    isno(val) {
      if (val == 0) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    isnot(val) {
      this.permission.screen.enable == 1
        ? (this.disabled = true)
        : (this.disabled = false)
      if (this.permission.screen.collect == 1) {
        this.permission.screen.enable = ''
        this.disabled = false
      }
      this.$emit('water', this.permission)
    },
    getRowKeys(row) {
      return row.id
    },
    inpbaohan() {
      this.$emit('water', this.permission)
    },
    changeSelet() {
      this.$emit('water', this.permission)
    },
    changeInput() {
      this.$emit('water', this.permission)
    },
    changeAll() {
      this.$emit('water', this.permission)
    },
    changeAll1(val) {
      if (val == 0) {
        this.diEr = true
        this.permission.clearData.fileTypes = [0, '*']
        this.permission.clearData.abcd = ''
      } else if (val == 1) {
        this.permission.clearData.fileTypes = [1, '']
        this.diEr = false
        if (
          this.permission.clearData.fileTypes[1] == '*' &&
          this.permission.clearData.fileTypes[1].length == 1
        ) {
          this.permission.clearData.fileTypes = [1, '']
          this.permission.clearData.abcd = ''
        }
      }
      this.$emit('water', this.permission)
    },
    inputQin(val) { 
      if (val) {
        this.permission.clearData.fileTypes[1] =
          this.permission.clearData.abcd.replace(/，/, ',')
      }
      if (val == '') {
        this.permission.clearData.fileTypes[1] = ''
      }
      this.$emit('water', this.permission)
    },
    changeAll2(val) {
      this.permission.clearData.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.clearData.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    changeAll3(val) {
      this.permission.clearData.fileTypes.forEach((item, index) => {
        if (item == '*') {
          this.permission.clearData.fileTypes.splice(index, 1)
        }
      })
      this.$emit('water', this.permission)
    },
    // 系统分享
    testsystem(val) {
      if (val === '1') {
        this.permission.sysShare.use = val
        this.shareBox = true
      } else {
        this.permission.sysShare.use = val
        this.shareBox = false
      }
      // 系统分享开关 == 1  开启
      // if (this.permission.sysShare.use == 1) {
      //   this.sysflag = true //复制粘贴保护  的开关开启
      //   this.spanflag = false //禁止系统的  描述关闭
      //   // 如果禁止系统 == 1 选中
      //   if (
      //     this.permission.sysShare.use == 1 &&
      //     this.permission.sysShare.enable == 1
      //   ) {
      //     this.spanflag = true //字体显示
      //     this.copyflag = true //复制、粘贴保护开关   开启
      //     this.permission.copyPaste.use = '1' //use=1开启开关
      //     this.permission.copyPaste.enable = 2 //默认选中第二个单选
      //     this.copydisable = true //复制、站衣蛾保护的两个禁用单选disable
      //     // this.morechecked1()
      //   }
      // } else {
      //   this.sysflag = false
      //   this.copydisable = false //让粘贴下面的两个单选为可选
      // }
      this.$emit('water', this.permission)
    },
    morechecked1(val) {
      if (this.permission.sysShare.enable == 1) {
        this.spanflag = true //字体显示
        this.copyflag = true //赋值粘贴开关开启
        this.permission.copyPaste.use = '1' //开关等于true
        this.permission.copyPaste.enable = 2
        this.copydisable = true //让粘贴下面的两个单选为禁用
      } else {
        this.spanflag = false
        this.permission.copyPaste.use = '0' //开关等于true
        this.copyflag = false //赋值粘贴开关开启
        this.copydisable = false //让粘贴下面的两个单选为可选
      }
      this.$emit('water', this.permission)
    },

    // 清除数据  开关 Switch
    clearDataMethods(val) {
      if (this.permission.clearData.use === '1') {
        this.clearBox = true
        // this.permission.clearData.fileTypes = val
      } else {
        // this.permission.clearData.fileTypes = val
        this.clearBox = false
      }
      this.$emit('water', this.permission)
    },
    // 清除数据 所有 文件 复选框
    clearAllFiles(val) {
      // console.log(val, "所有 文件");
    },
    // 清除数据 db 文件 复选框
    clearDbFiles(val) {
      // console.log(val, "db 文件");
    },
    // 清除数据 xml 文件 复选框
    clearXmlFiles(val) {
      // console.log(val, "xml 文件");
    },
    // 电子围栏 采集行为
    caiJi(val) {
      this.$emit('water', this.permission)
    },

    // 电子围栏 开关 Switch
    electronic(val) {
      if (this.permission.electronFences.use === '1') {
        this.weiLan = true
        // this.permission.clearData.fileTypes = val
      } else {
        // this.permission.clearData.fileTypes = val
        this.weiLan = false
      }
      this.$emit('water', this.permission)
    },
    // geographic  地理围栏 按钮
    geographic() {
      this.diLi = true //点击打开详情弹框
      this.geographyData() //调用 地理围栏列表
    },
    async geographyData() {
      var param = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule
        // pageNumber: this.currentPage4, //页数
        // rowNumber: this.pageSize, //行数
        // sort: "geo.lastUpdateTime desc", //排序, desc-倒序， asc-正序, 支持字段：r.name(框架名称), r.updateTime(修改时间)
      }
      const res = await this.$axios.post(
        '/httpServe/geofence/getDataInfo',
        param,
        true
      )
      this.tableInner = res.data.content
      this.page.total = res.data.total
      this.$nextTick(() => {
        this.tableInner.forEach((item, index) => {
          this.tableOutside.forEach((i, j) => {
            if (item.id == i.id) {
              // this.idsDiLi = item.id;
              // console.log(this.idsDiLi,'this.idsDiLi-09987654321')
              this.$refs.diLiMultipleTable.toggleRowSelection(item, true)
            }
          })
        })
      })
      this.$emit('water', this.permission)
    },
    handleCloseDiLi() {
      this.diLi = false //点击关闭详情弹框
    },
    //弹框中列表 change的改变事件
    handleSelectionChange(val) {
      this.diLieSelection = [...val]
    },
    // 地理围栏 弹框 （复选框）
    joinType3() {
      this.tableOutside = []
      this.diLieSelection.forEach((item, index) => {
        this.tableOutside.push({
          id: item.id,
          name: item.name
          // gwAddress: item.gwAddress,
          // gwPort: item.gwPort,
          // resourceNames: item.resourceNames,
        }) 
      })
      var result = []
      var obj = {}
      for (var i = 0; i < this.tableOutside.length; i++) {
        if (!obj[this.tableOutside[i].name]) {
          result.push(this.tableOutside[i])
          obj[this.tableOutside[i].name] = true
        }
      }
      this.tableOutside = result 
      this.diGeoFences = []
      this.tableOutside.forEach((item, index) => {
        this.diGeoFences.push(item.id + '')
      }) 
      console.log(this.diGeoFences)
      this.permission.electronFences.geofencesIds = this.diGeoFences
      this.diLi = false
      this.$emit('water', this.permission)
    },
    // 点击加入
    diLiDetermine() {
      // if (this.twoWater.length == 0) {
      //   this.$message.error("请选择界面水印");
      //   return false;
      // }
      this.joinType3()
      // this.diLi = false;
    },
    // 删除
    delapp(val) {
      this.tableOutside.forEach((item, index) => {
        if (val.name == item.name) {
          this.tableOutside.splice(index, 1)
          this.permission.electronFences.geofencesIds.splice(index, 1)
          this.$refs.diLiMultipleTable.clearSelection()
        }
      })
      this.$refs.diLiMultipleTable.clearSelection()
      this.$emit('water', this.permission)
    },
    // 复制、粘贴
    copy(val) {
      if (this.permission.copyPaste.use == 1) {
        this.copyflag = true
      } else {
        this.copyflag = false //开关是否显示复制粘贴
        this.permission.sysShare.enable = '0'
        this.spanflag = false
      }
      this.$emit('water', this.permission)
    },
    // 单选
    copyvalue(val) {
      this.$emit('water', this.permission)
    },
    // 高度
    heightCustom() {
      this.height.height = this.num
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false //点击出现详情弹框
      this.input = '' //选择界面水印的条件清空
    },
    handleCloseShare() {
      this.statusVisibleShare = false //点击出现详情弹框
      this.iptShare = '' //选择界面水印的条件清空
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-text {
  width: 100%;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 0px !important;
}
::v-deep .demo-ruleForm .el-form-item {
  margin-bottom: 0px !important;
}
</style>
