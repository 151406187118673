<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.PrivacyProtectionContent')}}</p>
      <div>
        <el-form
          ref="form"
          label-width="151px"
          class="demo-ruleForm"
          style="padding: 0 120px"
        >
          <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.SystemPermissionRestriction')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.SystemAuthorityContent1')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.SystemAuthorityContent2')}}
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.sysLimit.use"
            ></el-switch>
          </el-form-item>
          <div v-show="flagtrue">
            <el-form ref="form" label-width="200px" style="margin-left: -22px">
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.Call')">

                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.call.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.call.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.call.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'"  :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ReadTheCallLog')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.call_logs.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.call_logs.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.call_logs.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio2(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio2(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio2(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio2(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ModifyDeleteCallHistory')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.update_call_logs.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.update_call_logs.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.update_call_logs.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio3(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio3(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio3(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio3(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.SendATextMessage')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.send_sms.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.send_sms.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.send_sms.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio4(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio4(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio4(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio4(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ReadSMS')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.query_sms.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.query_sms.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.query_sms.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio5(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio5(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio5(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio5(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ModifyDeleteSMSRecords')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.update_sms.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.update_sms.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.update_sms.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio6(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio6(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio6(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio6(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ReadThePhoneNumber')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.phone.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.phone.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.phone.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio7(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio7(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio7(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio7(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ReadTheContact')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.contacts.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.contacts.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.contacts.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio8(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio8(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio8(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio8(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ModifyDeleteContact')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.update_contacts.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.update_contacts.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.update_contacts.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio9(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio9(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio9(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio9(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.RecordedAT')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.audio.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.audio.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.audio.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio10(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio10(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio10(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio10(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.TakePictures')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.camera.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.camera.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.camera.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio11(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio11(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio11(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio11(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AccessMultimediaResources')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.media_res.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.media_res.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.media_res.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio12(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio12(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio12(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio12(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.GetGeographicalLocation')">
                <el-checkbox
                  style="margin-left: 15px"
                  v-model="permission.sysLimit.location.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.sysLimit.location.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left: 30px"
                  v-model="permission.sysLimit.location.action"
                  @change="checkbox"
                >
                   <el-radio :label="4" @click.native.prevent="checkboxRadio13(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio13(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio13(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio13(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.MonitoringThirdPartySDKS')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
               {{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.monitorSDKContent1')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.monitorSDKContent2')}}<br />{{$t('AppManage.ApplicationStrategy.CurrentPage.PrivacyProtectionTab.monitorSDKContent3')}}
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test1"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.sdk.use"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataArray: {
      type: Object,
      default: () => {},
    },
    type:{
       type:String,
       default:""
     }
  },
  data() {
    return {
      flagtrue: false,
      permission: {
        sysLimit: {
          use: "0",
          call: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          call_logs: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          update_call_logs: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          send_sms: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          query_sms: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          update_sms: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          phone: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          contacts: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          update_contacts: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          audio: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          camera: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          media_res: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          location: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
        },
        sdk: {
          use: "0",
        },
      },
    };
  },
  created() {
    // 界面水印
    if (this.dataArray) {
      if (
        this.permission.sysLimit !=
        this.dataArray.appPermission.permission.sysLimit
      ) {
        this.permission.sysLimit =
          this.dataArray.appPermission.permission.sysLimit;
        this.permission.sysLimit.use =
          this.dataArray.appPermission.permission.sysLimit.use;
        this.test(this.permission.sysLimit.use);
      }
      // 文件透明加解密
      if (this.permission.sdk != this.dataArray.appPermission.permission.sdk) {
        this.permission.sdk = this.dataArray.appPermission.permission.sdk;
        this.permission.sdk.use =
          this.dataArray.appPermission.permission.sdk.use;
        this.test1(this.permission.sdk.use);
      }
    }
  },
  methods: {
    checkboxRadio(value){
      this.permission.sysLimit.call.action = value == this.permission.sysLimit.call.action ? '' : value 
    },
    checkboxRadio2(value){
      this.permission.sysLimit.call_logs.action = value == this.permission.sysLimit.call_logs.action ? '' : value 
    },
    checkboxRadio3(value){
      this.permission.sysLimit.update_call_logs.action = value == this.permission.sysLimit.update_call_logs.action ? '' : value 
    },
    checkboxRadio4(value){
      this.permission.sysLimit.send_sms.action = value == this.permission.sysLimit.send_sms.action ? '' : value 
    },
    checkboxRadio5(value){
      this.permission.sysLimit.query_sms.action = value == this.permission.sysLimit.query_sms.action ? '' : value 
    },
    checkboxRadio6(value){
      this.permission.sysLimit.update_sms.action = value == this.permission.sysLimit.update_sms.action ? '' : value 
    },
    checkboxRadio7(value){
      this.permission.sysLimit.phone.action = value == this.permission.sysLimit.phone.action ? '' : value 
    },
    checkboxRadio8(value){
      this.permission.sysLimit.contacts.action = value == this.permission.sysLimit.contacts.action ? '' : value 
    },
    checkboxRadio9(value){
      this.permission.sysLimit.update_contacts.action = value == this.permission.sysLimit.update_contacts.action ? '' : value 
    },
    checkboxRadio10(value){
      this.permission.sysLimit.audio.action = value == this.permission.sysLimit.audio.action ? '' : value 
    },
    checkboxRadio11(value){
      this.permission.sysLimit.camera.action = value == this.permission.sysLimit.camera.action ? '' : value 
    },
    checkboxRadio12(value){
      this.permission.sysLimit.media_res.action = value == this.permission.sysLimit.media_res.action ? '' : value 
    },
    checkboxRadio13(value){
      this.permission.sysLimit.location.action = value == this.permission.sysLimit.location.action ? '' : value 
    },
    test(val) {
      if (this.permission.sysLimit.use == 1) {
        this.flagtrue = true;
      } else {
        this.flagtrue = false;
      }
      this.$emit("privacy", this.permission);
    },
    checkbox(val) {
    },
    test1(val) {
      this.$emit("privacy", this.permission);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
   margin-bottom: 0px; 
}
::v-deep .el-form .el-form-item {
  margin-bottom: 0px !important;
}
</style>