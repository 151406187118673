<template>
  <div>
    <div class="title" v-if="flag == 1" style="margin-right: 20px">
      <el-row :gutter="3" style="padding: 10px 0 0 10px">
        <el-col :span="3">
          <el-input
            size="small"
            :placeholder="
              $t('AppManage.ApplicationStrategy.CurrentPage.placeholder')
            "
            v-model.trim="input"
            name="input"
            @keyup.enter.native="onSbumit"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            style="height: 31px"
            size="small"
            @click="onSbumit"
            >{{ $t("public.Inquire") }}</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="3" style="padding: 10px 0 0 10px">
        <el-button-group>
          <el-button type="primary" size="small" @click="addAndroid">
            {{ $t("AppManage.ApplicationStrategy.CurrentPage.addAndroid") }}
          </el-button>
          <el-button type="primary" size="small" @click="addIos">
            {{ $t("AppManage.ApplicationStrategy.CurrentPage.addIOS") }}
          </el-button>
          <el-button type="primary" size="small" @click="addWindows">
            {{ $t("AppManage.ApplicationStrategy.CurrentPage.addWindows") }}
          </el-button>
          <el-button type="primary" size="small" @click="addLinux">
            {{ $t("AppManage.ApplicationStrategy.CurrentPage.addLinux") }}
          </el-button>
          <el-button
            style="
              border-radius: 0px 3px 3px 0px;
              margin: 0px 0px 0px 0px;
              margin-left: 1px;
            "
            @click="delMuch(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </el-row>
      <el-row :gutter="16" class="mt16" style="padding-left: 18px">
        <!-- <div
          @click="maskLayer"
          class="callenia"
          style="
            width: 55px;
            height: 0px;
            background: rgba(255,  255,  255, 0.1);
            position: absolute;
            top: 6px;
            left: 20px;
            z-index: 9999;
            font-size: 12px;
          "
        >
          <i
            class="el-icon-caret-bottom"
            style="
              position: absolute;
              top: 12px;
              left: 24px;
              color: #969696;
              font-size: 14px;
            "
          ></i>
        </div> -->
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          :row-key="getRowKeys"
          size="mini"
          stripe
          ref="multipleTable"
          :data="tableData"
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          @selection-change="handleSelectionChange"
          :default-sort="{ prop: 'lastupdatetime', order: 'descending' }"
          @sort-change="sort"
        >
          <el-table-column
            :reserve-selection="true"
            header-align="center"
            align="center"
            type="selection"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            sortable="custom"
            :label="
              $t(
                'AppManage.ApplicationStrategy.CurrentPage.PolicyList.PolicyName'
              )
            "
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="ostypeid"
            sortable="custom"
            :label="$t('public.OperatingSystem')"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.osTypeId == 20"> Android </template>
              <template v-else-if="row.osTypeId == 10"> iOS </template>
              <template v-else-if="row.osTypeId == 30"> Windows </template>
              <template v-else-if="row.osTypeId == 40"> Linux </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="groupfullname"
            :show-overflow-tooltip="true"
            sortable="custom"
            :label="$t('public.Section')"
            width="150"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="关联应用" width="150">
            <template v-slot="{ row }">
              <span
                v-if="row.appCount <= 0"
                @click="nameKuang(row)"
                style="color: #606266"
              >
                {{ row.appCount }}
              </span>
              <span
                v-else
                @click="nameKuang(row)"
                style="color: #d38a08; cursor: pointer"
              >
                {{ row.appCount }}
              </span>
            </template>
          </el-table-column> -->
          <el-table-column
            sortable="custom"
            prop="lastupdatetime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span
              ><span class="detaildesc" v-else>
                {{ scope.row.lastUpdateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
                <img
                  @click="nameKuang(row)"
                  src="@/assets/icon_see.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="
                    $t(
                      'AppManage.ApplicationStrategy.CurrentPage.PolicyList.AssociatedApplication'
                    )
                  "
                />
              </span>
              <span class="action_icon">
                <img
                  @click="edit(row)"
                  :title="$t('public.Editor')"
                  src="../../../assets/icon_write.png"
                  alt=""
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
              <span class="action_icon">
                <img
                  v-show="row.name != '社交聊天'"
                  @click="deletes(row)"
                  :title="$t('public.Delete')"
                  src="../../../assets/icon_del.png"
                  alt=""
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination
            :page="listData.currentPage"
            :limit="listData.pageSize"
            :total="listData.total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </div>
        <!-- <ul class="tableCheckBox" style="" v-show="isCheckShow" @mouseleave="leave">
          <li @click="curSelection()">当前页全选</li>

          <li @click="toggleSelection()">取消全选</li>
        </ul> -->
      </el-row>
    </div>
    <!-- 新增Android策略 -->
    <div v-if="flag == 2" style="padding: 10px 0 0 10px">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        class="tabs"
        style="overflow: auto"
      >
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyName'
            )
          "
          name="1"
        >
          <el-form
            size="small"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            @submit.native.prevent
            class="demo-ruleForm"
            style="padding-left: 100px; padding-top: 20px"
          >
            <el-form-item
              :label="
                $t(
                  'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyNameContent'
                )
              "
              :required="true"
            >
              <el-input
                size="small"
                style="width: 260px"
                v-model.trim="ruleForm.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- <One></One> -->
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.DataProtection'
            )
          "
          name="2"
        >
          <ProtectionData
            @water="water"
            :type="'android'"
            :osTypeId="osTypeId"
            :dataProtection="dataProtection"
          ></ProtectionData>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PrivacyProtection'
            )
          "
          name="3"
        >
          <PrivacyProtection
            @privacy="privacy"
            :dataArray="dataProtection"
          ></PrivacyProtection>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.NetworkSecurity'
            )
          "
          name="4"
        >
          <NetworkSecurity
            @network="network"
            :anquan="dataProtection"
          ></NetworkSecurity>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.ThreatMonitoring'
            )
          "
          name="5"
        >
          <ThreatMonitoring
            @threat="threat"
            :detection="dataProtection"
          ></ThreatMonitoring>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.CrashMonitoring'
            )
          "
          name="6"
        >
          <CrashTesting
            @bengkui="bengkui"
            :crashtest="dataProtection"
          ></CrashTesting>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.BehaviorAudit'
            )
          "
          name="7"
        >
          <ConductTheAudit
            ref="sevens"
            @xingwei="xingwei"
            :audit="dataProtection"
          ></ConductTheAudit>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.AccessControl'
            )
          "
          name="8"
        >
          <AccessControl
            ref="eight"
            @accessControl="accessControl"
            :access="dataProtection"
          ></AccessControl>
        </el-tab-pane>
        <!-- <el-tab-pane label="应用权限" name="8">
          <Eight @appQuanXian="appQuanXian" :appa="dataProtection"></Eight>
        </el-tab-pane> -->
      </el-tabs>
      <!-- @click="submitForm('ruleForm')" @click="save" -->
      <el-row :gutter="16" style="padding: 10px 10px">
        <el-col :span="24">
          <el-button
            class="buttons"
            style="width: 100px; margin-left: 116px"
            type="primary"
            size="small"
            @click="submitForm('ruleForm')"
            >{{ $t("public.Save") }}</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 新增iOS策略 -->
    <div v-if="flag == 3" style="padding: 10px 0 0 10px">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        class="tabs"
        style="overflow: auto"
      >
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyName'
            )
          "
          name="1"
        >
          <el-form
            size="small"
            @submit.native.prevent
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            style="padding-left: 100px; padding-top: 20px"
          >
            <el-form-item
              :label="
                $t(
                  'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyNameContent'
                )
              "
              :required="true"
            >
              <el-input
                size="small"
                style="width: 260px"
                v-model.trim="ruleForm.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- <IosOne></IosOne> -->
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.DataProtection'
            )
          "
          name="2"
        >
          <ProtectionData
            @water="water"
            :type="'ios'"
            :osTypeId="osTypeId"
            :dataProtection="dataProtection"
          ></ProtectionData>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PrivacyProtection'
            )
          "
          name="3"
        >
          <PrivacyProtection
            @privacy="privacy"
            :type="'ios'"
            :dataArray="dataProtection"
          ></PrivacyProtection>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.NetworkSecurity'
            )
          "
          name="4"
        >
          <NetworkSecurity
            @network="network"
            :type="'ios'"
            :anquan="dataProtection"
          ></NetworkSecurity>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.ThreatMonitoring'
            )
          "
          name="5"
        >
          <ThreatMonitoring
            @threat="threat"
            :type="'ios'"
            :detection="dataProtection"
          ></ThreatMonitoring>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.CrashMonitoring'
            )
          "
          name="6"
        >
          <CrashTesting
            @bengkui="bengkui"
            :type="'ios'"
            :crashtest="dataProtection"
          ></CrashTesting>
        </el-tab-pane>
        <!-- <el-tab-pane :label="应用权限" name="7">
          <ApplicationPermissions @appQuanXian="appQuanXian" :type="'ios'" :appa="dataProtection"></ApplicationPermissions>
        </el-tab-pane> -->
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.AccessControl'
            )
          "
          name="8"
        >
          <AccessControl
            ref="eight"
            @accessControl="accessControl"
            :access="dataProtection"
          ></AccessControl>
        </el-tab-pane>
      </el-tabs>
      <el-row :gutter="16" style="padding: 10px 10px">
        <!--   @click="submitForm1('ruleForm1')" -->
        <el-col :span="24">
          <el-button
            type="primary"
            style="width: 100px; margin-left: 116px"
            size="small"
            @click="submitForm('ruleForm')"
            >{{ $t("public.Save") }}</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 新增Windows -->
    <div v-if="flag == 4" style="padding: 10px 0 0 10px">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        class="tabs"
        style="overflow: auto"
      >
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyName'
            )
          "
          name="1"
        >
          <el-form
            size="small"
            @submit.native.prevent
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            style="padding-left: 100px; padding-top: 20px"
          >
            <el-form-item
              :label="
                $t(
                  'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyNameContent'
                )
              "
              :required="true"
            >
              <el-input
                size="small"
                style="width: 260px"
                v-model.trim="ruleForm.name"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.AccessControl'
            )
          "
          name="8"
        >
          <AccessControl
            ref="eight"
            @accessControl="accessControl"
            :access="dataProtection"
          ></AccessControl>
        </el-tab-pane>
      </el-tabs>
      <el-row :gutter="16" style="padding: 10px 10px">
        <!--   @click="submitForm1('ruleForm1')" -->
        <el-col :span="24">
          <el-button
            type="primary"
            style="width: 100px; margin-left: 116px"
            size="small"
            @click="submitForm('ruleForm')"
            >{{ $t("public.Save") }}</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 新增Linux v6.3.0 -->
    <div v-if="flag == 5" style="padding: 10px 0 0 10px">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        class="tabs"
        style="overflow: auto"
      >
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyName'
            )
          "
          name="1"
        >
          <el-form
            size="small"
            @submit.native.prevent
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            style="padding-left: 100px; padding-top: 20px"
          >
            <el-form-item
              :label="
                $t(
                  'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.PolicyNameContent'
                )
              "
              :required="true"
            >
              <el-input
                size="small"
                style="width: 260px"
                v-model.trim="ruleForm.name"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.tacticsTab.AccessControl'
            )
          "
          name="8"
        >
          <LinuxAccessControl
            ref="eight"
            @accessControl="accessControl"
            :access="dataProtection"
          ></LinuxAccessControl>
        </el-tab-pane>
      </el-tabs>
      <el-row :gutter="16" style="padding: 10px 10px">
        <!--   @click="submitForm1('ruleForm1')" -->
        <el-col :span="24">
          <el-button
            type="primary"
            style="width: 100px; margin-left: 116px"
            size="small"
            @click="submitForm('ruleForm')"
            >{{ $t("public.Save") }}</el-button
          >
        </el-col>
      </el-row>
    </div>
    <Eldialog
      :title="
        $t(
          'AppManage.ApplicationStrategy.CurrentPage.PolicyList.AssociatedApplication'
        )
      "
      :num="'470px'"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
    >
      <el-input
        v-model="ipt"
        @keyup.enter.native="updateData"
        @input="(e) => (ipt = validForbid(e))"
        size="small"
        style="width: 180px; margin-bottom: 10px"
        :placeholder="
          $t(
            'AppManage.ApplicationStrategy.CurrentPage.PolicyList.placeholderApp'
          )
        "
        ><i
          @click="updateData"
          slot="suffix"
          class="el-input__icon el-icon-search"
          style="cursor: pointer"
        ></i
      ></el-input>
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        size="mini"
        stripe
        ref="multipleTable"
        :data="tableDataNmae"
        class="tableClass"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="name"
          :label="
            $t('AppManage.ApplicationStrategy.CurrentPage.PolicyList.appName')
          "
          width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="packagename"
          :label="
            $t(
              'AppManage.ApplicationStrategy.CurrentPage.PolicyList.ApplicationPackageName'
            )
          "
          width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="versionname"
          :label="$t('public.Version')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="UPDATE_TIME"
          :label="$t('public.ModificationTime')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.time == ''"></span
            ><span class="detaildesc" v-else>
              {{ scope.row.lastupdatetime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <p style="margin-top: 10px">
        {{ $t("public.total") }} <b>{{ this.dataTolCount }}</b
        >{{ $t("public.recordss") }}
      </p>
    </Eldialog>
    <!-- 提示消息框 -->
    <dialogInfo
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import Eldialog from "@/components/elDialog"; //详情弹框
import pagination from "@/components/pagination/page.vue"; //分页
import dialogInfo from "@/components/promptMessage/index"; //提示消息框
// Android策略8个页面 --新增
// import One from './appCL/one.vue'
import ProtectionData from "./appCL/protectionData.vue"; //./appCL/ProtectionData.vue
import PrivacyProtection from "./appCL/PrivacyProtection.vue"; //./appCL/PrivacyProtection.vue
import NetworkSecurity from "./appCL/NetworkSecurity.vue"; //./appCL/four.vue
import ThreatMonitoring from "./appCL/ThreatMonitoring.vue"; //./appCL/five.vue
import CrashTesting from "./appCL/CrashTesting.vue"; //./appCL/six.vue
import ConductTheAudit from "./appCL/ConductTheAudit.vue"; //./appCL/seven.vue
import ApplicationPermissions from "./appCL/ApplicationPermissions.vue";
import AccessControl from "./appCL/AccessControl.vue"; //访问控制
import LinuxAccessControl from "./appCL/LinuxAccessControl.vue"; //v6.3.0 新增Linux策略--访问控制
export default {
  components: {
    Eldialog,
    pagination,
    dialogInfo,
    // 子组件Android  --新增
    // One,
    ProtectionData,
    PrivacyProtection,
    NetworkSecurity,
    ThreatMonitoring,
    CrashTesting,
    ConductTheAudit,
    ApplicationPermissions,
    AccessControl,
    LinuxAccessControl,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      groupFullNameShowByIndex: "", //用户部门截取的下标
      id: "",
      osTypeId: "20",
      list: [{}], //子组件-----------
      listThree: [],

      flag: 1, //显示隐藏当前页
      activeName: "1", //默认显示当前页面
      activeNameI: "1", //默认显示当前页面
      input: "", //flag=1的查询
      ipt: "", //关联应的查询
      dataTolCount: "", //关联应用下的数据条数
      isCheckShow: false, //全选遮罩层
      statusVisible: false, //关联应用弹框默认不显示
      delarr: [], //多个删除存放数组
      visibaelFlag: false, //提示消息弹框默认不显示
      titleInfo: "", //提示消息
      // 列表参数
      listData: {
        total: 0,
        currentPage: 1, //pageNumber
        pageSize: 10, //rowNumber
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        name: "",
      },
      ruleForm: {
        name: "",
        radio: 2,
      },
      rules: {
        name: [
          { required: true, message: "请输入策略名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "最长输入30个字符，不支持空格及特殊字符",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: "名称不支持特殊字符",
            trigger: "blur",
          },
        ],
      },
      ruleForm1: {
        name: "",
        radio: 2,
      },
      rules1: {
        name: [
          { required: true, message: "请输入策略名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "最长输入30个字符，不支持空格及特殊字符",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: "名称不支持特殊字符",
            trigger: "blur",
          },
        ],
      },
      tableData: [], // 列表flag=1展示数据
      tableDataNmae: [], //列表详情展示
      // editVal: null, //当前编辑数据
      dataProtection: null, //Android 数据保护
      dataArray: {}, //Android  隐私保护
      anquan: {}, //Android  网络安全
      detection: {}, //Android 威胁检测
      crashtest: {}, //Android  崩溃检测
      audit: {}, //Android  行为审计
      appa: {}, //Android 应用权限
      submitFlag: false,
      waterConfigId: [], //界面水印
      library: "", //敏感词库
      geofencesIds: "", //围栏Id
      gatewayIds: "", //访问控制
      // Android
      permission: {
        watermark: {
          use: "0",
          // bright: '0', //明水印 0-关闭 1-开启
          // dark: '0', //暗水印 0-关闭 1-开启
          type: "2",
          waters: [],
        },
        encryptAndDecrypt: {
          use: "0",
        },
        safeInput: {
          use: "0",
          action: "0",
          collect: "0",
          safeKeyboard: "0",
          systemInput: "0",
        },
        screen: {
          use: "0",
          enable: "0",
          collect: "0",
          action: "0",
          upload: "2",
        },
        sysShare: {
          use: "0",
          enable: "0",
          collect: "0",
          action: "0",
          shareType: "0", //仅作 前端 回显 区分 第一个单选还是第二个
          forbidShare: {
            use: "0", //禁止分享 二选一 选中都为 1 ，不选中为0
            fileNames: "", //禁止分享 下文件名称 传值字符串以","分隔 英文逗号分隔
            fileTypes: [0, "*"], //禁止分享下选择的文件类型 传值为["*","doc,docx","xls,xlsx"]
          },
          forbidUnShare: {
            use: "0", //禁止分享 二选一 选中都为 1 ，不选中为0
            fileNames: "", //禁止分享 下文件名称 传值字符串以","分隔 英文逗号分隔
            fileTypes: [0, "*"], //禁止分享下选择的文件类型 传值为["*","doc,docx","xls,xlsx"]
          },
          fileShareEncrypt: {
            //文件分享加密 0-关闭 1-开启
            use: "0",
          },
          imgShareWatermark: {
            //图片分享加水印  0-关闭 1-开启
            use: "0",
            waters: [],
          },
        },
        clearData: {
          use: "0",
          timeUse: "0",
          time: "20",
          timeUnit: "",
          fileTypes: [0, "*"],
          fileNames: "",
        },
        //电子围栏
        electronFences: {
          //清除数据 0-开启 1-关闭
          use: "0",
          // 应用在地理围栏外使用：默认勾选后，且不可取消
          scope: "out", //in 内 out 应用围栏外使用
          geofencesIds: [], //围栏Id
          action: "0", //响应动作 4 告警提示 2 退出应用  8 清除数据
          collect: "0", //采集行为 0 未勾选 16 勾选
        },
        copyPaste: {
          use: "0",
          enable: "0",
          collect: "0",
          action: "0",
        },
        sysLimit: {
          use: "0",
          call: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          call_logs: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          update_call_logs: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          send_sms: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          query_sms: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          update_sms: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          phone: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          contacts: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          update_contacts: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          audio: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          camera: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          media_res: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          location: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
        },
        net: {
          use: "0",
          mobile_switch: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          mobile: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          wifi_switch: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          wifi: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
        },
        bluetooth: {
          use: "0",
          useBlueTooth: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          blue_switch: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
        },
        sdk: {
          use: "0",
        },
        crash: {
          use: "0",
        },
        app: {
          cerAuth: "0",
          permission: "0",
          sshTunnel: "0",
        },
        threat: {
          use: "0",
          inject: {
            collect: "0",
            action: "0",
          },
          frame: {
            collect: "0",
            action: "0",
          },
          debug: {
            collect: "0",
            action: "0",
          },
          location: {
            collect: "0",
            action: "0",
          },
          domain: {
            collect: "0",
            action: "0",
          },
          plugin: {
            collect: "0",
            action: "0",
          },
          signature: {
            collect: "0",
            action: "0",
          },
          memory_change: {
            collect: "0",
            action: "0",
          },
          false_device: {
            //设备伪造
            action: "0", //响应动作 4 告警提示 2 退出应用  8 清除数据
            collect: "0", //采集行为 0 未勾选 16 勾选
          },
          https_ca: {
            collect: "0",
            action: "0",
          },
          wifi_proxy: {
            collect: "0",
            action: "0",
          },
          multi_apk: {
            collect: "0",
            action: "0",
          },
          frame_attach: {
            collect: "0",
            action: "0",
          },
          danger_apps: {
            collect: "0",
            action: "0",
          },
          root_status: {
            collect: "0",
            action: "0",
          },
          adb: {
            collect: "0",
            action: "0",
          },
          vm: {
            collect: "0",
            action: "0",
          },
          sys_user_ca: {
            collect: "0",
            action: "0",
          },
          mock_location: {
            collect: "0",
            action: "0",
          },
          rom_custom: {
            collect: "0",
            action: "0",
          },
          windows: {
            collect: "0",
            action: "0",
          },
          macOS: {
            collect: "0",
            action: "0",
          },
          vpn: {
            collect: "0",
            action: "0",
          },
          cloud_phone: {
            collect: "0",
            action: "0",
          },
          danger_ip: {
            collect: "0",
            action: "0",
          },
          // 6.0.0建行新加检测项
          screen_password: {
            //锁屏密码未开启 采集、动作传值与其他参数保持一致
            collect: "0",
            action: "0",
          },
          system_time: {
            //系统时间异常 采集、动作传值与其他参数保持一致
            collect: "0",
            action: "0",
          },
          screenshot_status: {
            //截屏阻断解除 采集、动作传值与其他参数保持一致
            collect: "0",
            action: "0",
          },
        },
        chats: {
          use: "0",
          text: "0",
          sensitive: {},
          maxRequest: "",
          maxRate: "",
        },
        gateway: {
          switch: "0", //安全网关开关
          gwList: [],
        },
      },
      crashtestIos: {}, //ios 崩溃监测
      appaIos: {}, //ios 应用权限
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  created() {},
  // watch: {
  //     input(val) {
  //       this.$nextTick(() => {
  //         this.input = filterInput(val)
  //       })
  //     }
  //   },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.tableList(); //table列表
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },

    backLoces() {
      this.submitFlag = false;
      if (this.ruleForm.name.length > 30) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.maximumLengthRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      if (!this.ruleForm.name) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseEnterAPolicyNameRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.ruleForm.name)) {
        // this.titleInfo = '策略名称不能出现特殊字符'
        // this.visibaelFlag = true
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.notSpecialCharacterRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      if (
        this.permission.watermark.use == 1 &&
        this.permission.watermark.type == 2
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.WatermarkSelectAtLeastOneRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "2";
        });
        return false;
      } else if (
        this.permission.watermark.use == 1 &&
        (this.permission.watermark.type == 0 ||
          this.permission.watermark.type == 1) &&
        this.permission.watermark.waters.length == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseSelectAnInterfaceWatermarkRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "2";
        });
        return false;
      } else if (
        this.permission.safeInput.use == 1 &&
        this.permission.safeInput.collect == 0 &&
        this.permission.safeInput.action == 0 &&
        this.permission.safeInput.safeKeyboard == 0 &&
        this.permission.safeInput.systemInput == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SecurityInputSelectAtLeastOneConfigurationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "2";
        });
        return false;
      } else if (
        this.permission.screen.use == 1 &&
        this.permission.screen.enable == 0 &&
        this.permission.screen.action == 0 &&
        this.permission.screen.upload == 2
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.ScreenCaptureRecordProtectionSelectAtLeastOneConfigurationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "2";
        });
        return false;
      } else if (this.permission.clearData.use == 1) {
        if (
          this.permission.clearData.use == 1 &&
          this.permission.clearData.timeUse == 0 &&
          this.permission.clearData.fileTypes.length == 0 &&
          this.permission.clearData.fileNames == ""
        ) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.ScreenCaptureRecordProtectionSelectAtLeastOneConfigurationRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        if (
          this.permission.clearData.use == 1 &&
          this.permission.clearData.fileTypes[1].length == 0
        ) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseEnterACustomFileRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        if (this.permission.clearData.timeUse == 1) {
          if (this.permission.clearData.time == "") {
            this.$message({
              message: this.$t(
                "AppManage.ApplicationStrategy.CurrentPage.PolicyList.ConfigurePeriodicClearingRule"
              ),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "2";
            });
            return false;
          }
        }
        // permission.sysShare.forbidShare.fileTypes
        if (this.permission.clearData.fileTypes.length == 0) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SelectFileTypeSelectAtLeastOneItemRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }

        // if (this.permission.sysShare.forbidShare.use == 1) {
        //   if (this.permission.sysShare.forbidShare.fileTypes.length == 0) {
        //     this.$message({
        //       message: "至少勾选一项系统类型",
        //       type: "error",
        //       offset: 100,
        //     });
        //     this.$nextTick(() => {
        //       this.activeName = "2";
        //     });
        //     return false;
        //   }
        // }
      } else if (this.permission.clearData.use == 1) {
        if (
          this.permission.clearData.use == 1 &&
          this.permission.clearData.timeUse == 0 &&
          this.permission.clearData.fileTypes.length == 0 &&
          this.permission.clearData.fileNames == ""
        ) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.ClearDataSelectAtLeastOneConfigurationItemRule"
            ),
            type: "error",
            offset: 100,
          });
          return false;
        }
        if (this.permission.clearData.fileTypes.length == 0) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseSelectTheFileTypeRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
      }
      if (
        this.permission.copyPaste.use == 1 &&
        this.permission.copyPaste.enable == 0 &&
        this.permission.copyPaste.collect == 0 &&
        this.permission.copyPaste.action == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.CVRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "2";
        });
        return false;
      }
      // 系统权限限制至少选择一项配置
      if (
        this.permission.sysLimit.use == 1 &&
        this.permission.sysLimit.call.forbid == 0 &&
        this.permission.sysLimit.call.collect == 0 &&
        this.permission.sysLimit.call.action == 0 &&
        this.permission.sysLimit.call_logs.forbid == 0 &&
        this.permission.sysLimit.call_logs.collect == 0 &&
        this.permission.sysLimit.call_logs.action == 0 &&
        this.permission.sysLimit.update_call_logs.forbid == 0 &&
        this.permission.sysLimit.update_call_logs.collect == 0 &&
        this.permission.sysLimit.update_call_logs.action == 0 &&
        this.permission.sysLimit.send_sms.forbid == 0 &&
        this.permission.sysLimit.send_sms.collect == 0 &&
        this.permission.sysLimit.send_sms.action == 0 &&
        this.permission.sysLimit.query_sms.forbid == 0 &&
        this.permission.sysLimit.query_sms.collect == 0 &&
        this.permission.sysLimit.query_sms.action == 0 &&
        this.permission.sysLimit.update_sms.forbid == 0 &&
        this.permission.sysLimit.update_sms.collect == 0 &&
        this.permission.sysLimit.update_sms.action == 0 &&
        this.permission.sysLimit.phone.forbid == 0 &&
        this.permission.sysLimit.phone.collect == 0 &&
        this.permission.sysLimit.phone.action == 0 &&
        this.permission.sysLimit.contacts.forbid == 0 &&
        this.permission.sysLimit.contacts.collect == 0 &&
        this.permission.sysLimit.contacts.action == 0 &&
        this.permission.sysLimit.update_contacts.forbid == 0 &&
        this.permission.sysLimit.update_contacts.collect == 0 &&
        this.permission.sysLimit.update_contacts.action == 0 &&
        this.permission.sysLimit.audio.forbid == 0 &&
        this.permission.sysLimit.audio.collect == 0 &&
        this.permission.sysLimit.audio.action == 0 &&
        this.permission.sysLimit.camera.forbid == 0 &&
        this.permission.sysLimit.camera.collect == 0 &&
        this.permission.sysLimit.camera.action == 0 &&
        this.permission.sysLimit.media_res.forbid == 0 &&
        this.permission.sysLimit.media_res.collect == 0 &&
        this.permission.sysLimit.media_res.action == 0 &&
        this.permission.sysLimit.location.forbid == 0 &&
        this.permission.sysLimit.location.collect == 0 &&
        this.permission.sysLimit.location.action == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SystemConfigOneRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "3";
        });
        return false;
      } else if (
        this.permission.net.use == 1 &&
        this.permission.net.mobile_switch.forbid == 0 &&
        this.permission.net.mobile_switch.collect == 0 &&
        this.permission.net.mobile_switch.action == 0 &&
        this.permission.net.mobile.forbid == 0 &&
        this.permission.net.mobile.collect == 0 &&
        this.permission.net.mobile.action == 0 &&
        this.permission.net.wifi_switch.forbid == 0 &&
        this.permission.net.wifi_switch.collect == 0 &&
        this.permission.net.wifi_switch.action == 0 &&
        this.permission.net.wifi.forbid == 0 &&
        this.permission.net.wifi.collect == 0 &&
        this.permission.net.wifi.action == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.NetworkSelectAtLeastOneConfigurationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "4";
        });
        return false;
      } else if (
        this.permission.bluetooth.use == 1 &&
        this.permission.bluetooth.useBlueTooth.forbid == 0 &&
        this.permission.bluetooth.useBlueTooth.collect == 0 &&
        this.permission.bluetooth.useBlueTooth.action == 0 &&
        this.permission.bluetooth.blue_switch.forbid == 0 &&
        this.permission.bluetooth.blue_switch.collect == 0 &&
        this.permission.bluetooth.blue_switch.action == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.BluetoothOneConfigurationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "4";
        });
        return false;
      } else if (
        this.permission.threat.use == 1 &&
        this.permission.threat.inject.collect == 0 &&
        this.permission.threat.inject.action == 0 &&
        this.permission.threat.frame.collect == 0 &&
        this.permission.threat.frame.action == 0 &&
        this.permission.threat.debug.collect == 0 &&
        this.permission.threat.debug.action == 0 &&
        this.permission.threat.location.collect == 0 &&
        this.permission.threat.location.action == 0 &&
        this.permission.threat.domain.collect == 0 &&
        this.permission.threat.domain.action == 0 &&
        this.permission.threat.plugin.collect == 0 &&
        this.permission.threat.plugin.action == 0 &&
        this.permission.threat.signature.collect == 0 &&
        this.permission.threat.signature.action == 0 &&
        this.permission.threat.memory_change.collect == 0 &&
        this.permission.threat.memory_change.action == 0 &&
        this.permission.threat.https_ca.collect == 0 &&
        this.permission.threat.https_ca.action == 0 &&
        this.permission.threat.wifi_proxy.collect == 0 &&
        this.permission.threat.wifi_proxy.action == 0 &&
        this.permission.threat.multi_apk.collect == 0 &&
        this.permission.threat.multi_apk.action == 0 &&
        this.permission.threat.frame_attach.collect == 0 &&
        this.permission.threat.frame_attach.action == 0 &&
        this.permission.threat.danger_apps.collect == 0 &&
        this.permission.threat.danger_apps.action == 0 &&
        this.permission.threat.root_status.collect == 0 &&
        this.permission.threat.root_status.action == 0 &&
        this.permission.threat.adb.collect == 0 &&
        this.permission.threat.adb.action == 0 &&
        this.permission.threat.vm.collect == 0 &&
        this.permission.threat.vm.action == 0 &&
        this.permission.threat.mock_location.collect == 0 &&
        this.permission.threat.mock_location.action == 0 &&
        this.permission.threat.rom_custom.collect == 0 &&
        this.permission.threat.rom_custom.action == 0 &&
        this.permission.threat.screen_password.collect == 0 &&
        this.permission.threat.screen_password.action == 0 &&
        this.permission.threat.system_time.collect == 0 &&
        this.permission.threat.system_time.action == 0 &&
        this.permission.threat.screenshot_status.collect == 0 &&
        this.permission.threat.screenshot_status.action == 0 &&
        this.permission.threat.false_device.collect == 0 &&
        this.permission.threat.false_device.action == 0 &&
        this.permission.threat.macOS.collect == 0 &&
        this.permission.threat.macOS.action == 0 &&
        this.permission.threat.windows.collect == 0 &&
        this.permission.threat.windows.action == 0 &&
        this.permission.threat.cloud_phone.collect == 0 &&
        this.permission.threat.cloud_phone.action == 0 &&
        this.permission.threat.vpn.collect == 0 &&
        this.permission.threat.vpn.action == 0 &&
        this.permission.threat.danger_ip.collect == 0 &&
        this.permission.threat.danger_ip.action == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.monitoringAConfigurationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "5";
        });
        return false;
      } else if (
        this.permission.chats.use == 1 &&
        this.permission.chats.text == 0 &&
        this.permission.chats.sensitive.use == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.ChatContentAConfigurationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "7";
        });
        return false;
      } else if (
        this.permission.chats.use == 1 &&
        this.permission.chats.sensitive.use == 1 &&
        this.permission.chats.sensitive.library.length == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseSelectSensitiveThesaurusRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "7";
        });
        return false;
      } else if (
        this.permission.chats.use == 1 &&
        this.permission.chats.sensitive.use == 1 &&
        this.permission.chats.sensitive.replace == 0 &&
        this.permission.chats.sensitive.collect == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.DisableSensitiveWordsAndAuditChatSelectAtLeastOneSensitiveWordForInputInformationRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "7";
        });
        return false;
      } else if (
        //访问控制
        this.permission.gateway.use == 1 &&
        this.permission.gateway.gwList.length == 0
      ) {
        this.$message({
          message: this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseSelectAGatewayResourceRule"
          ),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "8";
        });
        return false;
      }
      if (this.permission.sysShare.use == 1) {
        if (
          this.permission.sysShare.forbidShare.use == 0 &&
          this.permission.sysShare.forbidUnShare.use == 0 &&
          this.permission.sysShare.fileShareEncrypt.use == 0 &&
          this.permission.sysShare.imgShareWatermark.use == 0 &&
          this.permission.sysShare.collect == 0 &&
          this.permission.sysShare.action == 0
        ) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SystemSharingSelectAtLeastOneItemRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        //
        if (
          this.permission.sysShare.forbidUnShare.use == 1 &&
          this.permission.sysShare.forbidUnShare.fileTypes[1].length == 0
        ) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseEnterACustomFileRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        if (
          this.permission.sysShare.forbidShare.use == 1 &&
          this.permission.sysShare.forbidShare.fileTypes[1].length == 0
        ) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseEnterACustomFileRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        if (this.permission.sysShare.forbidShare.use == 1) {
          if (this.permission.sysShare.forbidShare.fileTypes.length == 0) {
            this.$message({
              message: this.$t(
                "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SelectAtLeastOneFileTypeRule"
              ),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "2";
            });
            return false;
          }
        }
        if (this.permission.sysShare.use == 1) {
          if (this.permission.sysShare.imgShareWatermark.use == 1) {
            if (this.permission.sysShare.imgShareWatermark.waters.length == 0) {
              this.$message({
                message: this.$t(
                  "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseSelectAnInterfaceWatermarkRule"
                ),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "2";
              });
              return false;
            }
          }
        }
      }
      // 地理围栏
      if (this.permission.electronFences.use == 1) {
        // debugger
        if (this.permission.electronFences.geofencesIds.length == 0) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.PleaseSelectGeofencingRule"
            ),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
      }
      this.submitFlag = true;
    },
    // 编辑  edit  编辑回显
    async edit(val) {
      this.id = val.id;
      this.osTypeId = val.osTypeId;
      if (val.osTypeId == 20) {
        this.dataProtection = val;
        this.ruleForm.name = this.dataProtection.name;
        this.flag = 2;
        this.activeName = "1";
      } else if (val.osTypeId == 10) {
        this.dataProtection = val;
        this.ruleForm.name = this.dataProtection.name;
        this.flag = 3;
        this.activeName = "1";
      } else if (val.osTypeId == 30) {
        this.dataProtection = val;
        this.ruleForm.name = this.dataProtection.name;
        this.flag = 4;
        this.activeName = "1";
      } else if (val.osTypeId == 40) {
        this.dataProtection = val;
        this.ruleForm.name = this.dataProtection.name;
        this.flag = 5;
        this.activeName = "1";
      } else {
        this.dataProtection = {};
      }
    },
    async save() {
      if (this.id) {
        console.log(this.id, "-------------编辑");
        if (this.osTypeId == "20") {
          var param = {
            id: this.id,
            name: this.ruleForm.name,
            type: this.osTypeId,
            waterConfigId: this.waterConfigId.join(","),
            library: this.library,
            geofencesIds: this.geofencesIds,
            gatewayIds: this.gatewayIds,
            action: "edit",
            appPermission: {
              app: {
                pkg: "",
              },
              ver: "1",
              permission: this.permission,
              desc: "app-permission",
            },
          };
          const res = await this.$axios.post(
            "/httpServe/entAppPermission/update",
            param,
            true
          );
          if (res.data == -1) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SaveFailure"
            ),
            type: "error",
            offset: 100,
          });
        }else{
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.activeName = "1";
          this.$set(this.ruleForm, "name", "");
          this.flag = 1;
          this.input = "";
          this.dataProtection = {};
        }
          
          
        } else if (this.osTypeId == "10") {
          this.permission.chats = {};
          var param = {
            id: this.id,
            name: this.ruleForm.name,
            type: this.osTypeId,
            waterConfigId: this.waterConfigId.join(","),
            library: this.library,
            geofencesIds: this.geofencesIds,
            gatewayIds: this.gatewayIds,
            action: "edit",
            appPermission: {
              app: {
                pkg: "",
              },
              ver: "1",
              permission: this.permission,
              desc: "app-permission",
            },
          };
          const res = await this.$axios.post(
            "/httpServe/entAppPermission/update",
            param,
            true
          );
          if (res.data == -1) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SaveFailure"
            ),
            type: "error",
            offset: 100,
          });
        }else{
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.activeName = "1";
          this.$set(this.ruleForm, "name", "");
          this.flag = 1;
          this.input = "";
          this.dataProtection = {};
        }
          
          
        } else if (this.osTypeId == "30") {
          this.permission.chats = {};
          var param = {
            id: this.id,
            name: this.ruleForm.name,
            type: this.osTypeId,
            waterConfigId: this.waterConfigId.join(","),
            library: this.library,
            geofencesIds: this.geofencesIds,
            gatewayIds: this.gatewayIds,
            action: "edit",
            appPermission: {
              app: {
                pkg: "",
              },
              ver: "1",
              permission: this.permission,
              desc: "app-permission",
            },
          };
          const res = await this.$axios.post(
            "/httpServe/entAppPermission/update",
            param,
            true
          );
          if (res.data == -1) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SaveFailure"
            ),
            type: "error",
            offset: 100,
          });
        }else{
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.activeName = "1";
          this.$set(this.ruleForm, "name", "");
          this.flag = 1;
          this.input = "";
          this.dataProtection = {};
        }
          
          
        } else if (this.osTypeId == "40") {
          this.permission.chats = {};
          var param = {
            id: this.id,
            name: this.ruleForm.name,
            type: this.osTypeId,
            waterConfigId: this.waterConfigId.join(","),
            library: this.library,
            geofencesIds: this.geofencesIds,
            gatewayIds: this.gatewayIds,
            action: "edit",
            appPermission: {
              app: {
                pkg: "",
              },
              ver: "1",
              permission: this.permission,
              desc: "app-permission",
            },
          };
          const res = await this.$axios.post(
            "/httpServe/entAppPermission/update",
            param,
            true
          );
          if (res.data == -1) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SaveFailure"
            ),
            type: "error",
            offset: 100,
          });
        }else{
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.activeName = "1";
          this.$set(this.ruleForm, "name", "");
          this.flag = 1;
          this.input = "";
          this.dataProtection = {};
        }
          
          
        }
      } else {
        var param = {
          name: this.ruleForm.name,
          osTypeId: this.osTypeId,
          waterConfigId: this.waterConfigId.join(","),
          library: this.library,
          geofencesIds: this.geofencesIds, //围栏Id
          gatewayIds: this.gatewayIds,
          action: "add",
          appPermission: {
            app: {
              pkg: "",
            },
            ver: "1",
            permission: this.permission,
            desc: "app-permission",
          },
        };
        const res = await this.$axios.post(
          "/httpServe/entAppPermission/save",
          param,
          true
        );
        if (res.data == -1) {
          this.$message({
            message: this.$t(
              "AppManage.ApplicationStrategy.CurrentPage.PolicyList.SaveFailure"
            ),
            type: "error",
            offset: 100,
          });
        } else {
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.activeName = "1";
          this.$set(this.ruleForm, "name", "");
          this.flag = 1;
          this.input = "";
        }
      }
      this.tableList();
      // this.tableDataNmae = res.data; //列表数据
      // this.dataTolCount = res.totalCount; //列表总数
    },
    // ----Android   *******************
    // 数据保护  --界面水印
    water(all) {
      console.log(all, "数据保护回显");
      var waterId = [];
      var arr = [];
      var arr2 = [];
      var arr3 = [];
      var uniqueArr = [];
      if (all.watermark.waters == undefined) {
        all.watermark.waters = [];
      }
      if (all.sysShare.imgShareWatermark.waters == undefined) {
        all.sysShare.imgShareWatermark.waters = [];
      }

      // this.waterConfigId=[...new Set(waterId)]

      if (all.sysShare.imgShareWatermark.waters.length > 0) {
        arr = [all.sysShare.imgShareWatermark.waters[0].id + ""];
        this.waterConfigId = arr;
      }
      if (all.watermark.waters.length > 0) {
        arr2 = [all.watermark.waters[0].id + ""];
        this.waterConfigId = arr2;
      }
      if (
        all.sysShare.imgShareWatermark.waters.length > 0 &&
        all.watermark.waters.length > 0
      ) {
        arr3 = arr.concat(arr2);
        uniqueArr = [...new Set(arr3)];
        this.waterConfigId = uniqueArr;
        // console.log('去重3', this.waterConfigId);
      }

      this.geofencesIds = all.electronFences.geofencesIds.toString();
      if (all.watermark.use == 0) {
        this.permission.watermark.use = "0";
        this.permission.watermark.waters = [];
      } else {
        this.permission.watermark.use = all.watermark.use + "";
        this.permission.watermark = all.watermark;
        this.permission.watermark.waters = all.watermark.waters;
      }

      this.permission.encryptAndDecrypt = all.encryptAndDecrypt;
      this.permission.safeInput = all.safeInput;

      this.permission.screen = all.screen;
      if (all.sysShare.use == 1) {
        this.permission.sysShare = all.sysShare;
        this.permission.sysShare.forbidShare.fileTypes =
          all.sysShare.forbidShare.fileTypes;
        if (all.sysShare.imgShareWatermark.use == 0) {
          this.permission.sysShare.imgShareWatermark.waters = [];
        }
      }
      // this.permission.sysShare = all.sysShare
      // this.permission.sysShare.forbidShare.fileTypes =
      //   all.sysShare.forbidShare.fileTypes

      this.permission.copyPaste = all.copyPaste;
      // 6.0.0
      this.permission.watermark.type = all.watermark.type;
      this.permission.clearData = all.clearData;
      this.permission.clearData.fileTypes = all.clearData.fileTypes;
      // 6.0.0 优化版本 添加电子围栏
      this.permission.electronFences = all.electronFences;
    },
    // 隐私保护
    privacy(all) {
      this.permission.sysLimit = all.sysLimit;
      this.permission.sdk = all.sdk;
    },
    // // 网络安全 --网络
    network(all) {
      this.permission.net = all.net;
      this.permission.bluetooth = all.bluetooth;
    },
    // 威胁检测
    threat(all) {
      this.permission.threat = all.threat;
    },
    // 崩溃检测
    bengkui(all) {
      this.permission.crash.use = all.crash.use;
      // this.permission.crash.use = all.crash.use == true?1:0;
    },

    // 行为审计
    xingwei(all) {
      this.library = all.chats.sensitive.library + "";
      this.permission.chats = all.chats;
    },
    // 访问控制
    accessControl(all) {
      this.gatewayIds = all.chats.gwList + "";
      this.permission.gateway = all.chats;
    },
    // 访问控制
    accessControlIos(all) {
      this.gatewayIds = all.chats.gwList + "";
      this.permission.gateway = all.chats;
    },
    // 应用权限
    appQuanXian(all) {
      this.permission.app = all.app;
    },
    // 列表
    async tableList() {
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: this.input,
      };
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content; //列表数据
      this.listData.total = res.data.total; //列表总数
    },

    // 查询 onSbumit
    async onSbumit() {
      if (this.input.length > 0) {
        this.listData.currentPage = 1;
      }
      this.tableList();
    },
    // 排序
    sort(column) {
      // name  ostypeid   groupfullname  lastupdatetime
      if (column.prop == "name") {
        if (column.order == "ascending") {
          this.listData.orderColume = "name";
          this.listData.orderRule = "ASC";
        } else {
          this.listData.orderColume = "name";
          this.listData.orderRule = "DESC";
        }
      } else if (column.prop == "ostypeid") {
        if (column.order == "ascending") {
          this.listData.orderColume = "ostypeid";
          this.listData.orderRule = "ASC";
        } else {
          this.listData.orderColume = "ostypeid";
          this.listData.orderRule = "DESC";
        }
      } else if (column.prop == "groupfullname") {
        if (column.order == "ascending") {
          this.listData.orderColume = "groupfullname";
          this.listData.orderRule = "ASC";
        } else {
          this.listData.orderColume = "groupfullname";
          this.listData.orderRule = "DESC";
        }
      } else if (column.prop == "lastupdatetime") {
        if (column.order == "ascending") {
          this.listData.orderColume = "lastupdatetime";
          this.listData.orderRule = "ASC";
        } else {
          this.listData.orderColume = "lastupdatetime";
          this.listData.orderRule = "DESC";
        }
      }
      this.tableList();
    },
    // 删除
    deletes(val) {
      this.del(val);
    },
    // 删除接口
    async del(row) {
      var param = {
        ids: [row.id],
      };
      if (row.appCount > 0) {
        this.$confirm(
          this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.AssociatedDelConfirmPolicy"
          ),
          this.$t("public.PromptMessage"),
          {
            cancelButtonText: this.$t("public.Cancel"),
            confirmButtonText: this.$t("public.Verify"),
            customClass: "persdsd",
            type: "warning",
          }
        )
          .then(async () => {
            const res = await this.$axios.post(
              "/httpServe/entAppPermission/delete",
              param,
              true
            );
            if (res.code == 200) {
              this.$message({
                message: this.$t(
                  "AppManage.ApplicationStrategy.CurrentPage.PolicyList.delSuccess"
                ),
                type: "success",
                offset: 100,
              });
              this.listData.currentPage = "1";
              this.$refs.multipleTable.clearSelection();
              this.tableList();
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        this.$confirm(
          this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.DelConfirmPolicy"
          ),
          this.$t("public.PromptMessage"),
          {
            cancelButtonText: this.$t("public.Cancel"),
            confirmButtonText: this.$t("public.Verify"),
            customClass: "persdsd",
            type: "warning",
          }
        )
          .then(async () => {
            const res = await this.$axios.post(
              "/httpServe/entAppPermission/delete",
              param,
              true
            );
            if (res.code == 200) {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.listData.currentPage = "1";
              this.$refs.multipleTable.clearSelection();
              this.tableList();
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    //多条删除
    async delMuch(obj) {
      var appCount = 0;
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
        // console.log(item.appCount,"item.appCount");
        if (item.appCount > 0) {
          appCount = item.appCount;
        }
      });

      // console.log(appCount,"appCount");
      var param = {
        ids: ids,
      };
      if (appCount > 0) {
        this.$confirm(
          this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.AssociatedDelConfirmPolicy"
          ),
          this.$t("public.PromptMessage"),
          {
            cancelButtonText: this.$t("public.Cancel"),
            confirmButtonText: this.$t("public.Verify"),
            customClass: "persdsd",
            type: "warning",
          }
        )
          .then(async () => {
            const res = await this.$axios.post(
              "/httpServe/entAppPermission/delete",
              param,
              true
            );
            // if (res.data == -1) {
            //   this.$message({
            //     message: '删除失败',
            //     type: 'error',
            //     offset: 100
            //   })
            // } else {
            this.$message({
              message: this.$t(
                "AppManage.ApplicationStrategy.CurrentPage.PolicyList.delSuccess"
              ),
              type: "success",
              offset: 100,
            });
            this.listData.currentPage = "1";
            this.$refs.multipleTable.clearSelection();
            this.tableList();
            // }
          })
          .catch(() => {
            return false;
          });
      } else {
        this.$confirm(
          this.$t(
            "AppManage.ApplicationStrategy.CurrentPage.PolicyList.DelConfirmPolicy"
          ),
          this.$t("public.PromptMessage"),
          {
            cancelButtonText: this.$t("public.Cancel"),
            confirmButtonText: this.$t("public.Verify"),
            customClass: "persdsd",
            type: "warning",
          }
        )
          .then(async () => {
            const res = await this.$axios.post(
              "/httpServe/entAppPermission/delete",
              param,
              true
            );
            // if (res.data == -1) {
            //   this.$message({
            //     message: '删除失败',
            //     type: 'error',
            //     offset: 100
            //   })
            //   // this.titleInfo = "删除成功";
            //   // this.visibaelFlag = true;
            // } else {
            // this.titleInfo = '删除成功'
            // this.visibaelFlag = true
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.listData.currentPage = "1";
            this.$refs.multipleTable.clearSelection();
            this.tableList();
            // }
          })
          .catch(() => {
            return false;
          });
      }
    },
    // 点击关联应用详情
    async nameKuang(val) {
      this.id = val.id + "";
      this.statusVisible = true;
      var param = {
        id: val.id + "",
      };
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getRelateCount",
        param,
        true
      );
      this.tableDataNmae = res.data.relateApp;
      this.dataTolCount = res.data.relateCount;
    },
    // 关联应用弹框的查询
    async updateData() {
      var param = {
        id: this.id,
        appName: this.ipt,
      };
      // "/httpServe/entAppPermission/selectByName",
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getRelateCount",
        param,
        true
      );

      this.tableDataNmae = res.data.relateApp;
      this.dataTolCount = res.data.relateCount;
    },
    // 卡片切换
    handleClick(data) {
      // let name = data.name;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.backLoces();
        } else {
          this.$nextTick(() => {
            this.activeName = "1";
          });
        }
      });
    },
    // 卡片切换1
    handleClickI(data) {
      // let name = data.name;
      // let name = data.name;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        } else {
          this.$nextTick(() => {
            this.activeNameI = "1";
          });
        }
      });
    },
    //分页
    handleParentGetList(page, limit) {
      this.listData.currentPage = page;
      this.listData.pageSize = limit;
      // 调用列表
      this.tableList();
    },
    // 新增Android策略
    addAndroid() {
      // this.dataProtection = {}
      this.dataProtection = {};
      this.osTypeId = "20";
      this.flag = 2;
    },
    // 新增IOS策略
    addIos() {
      this.dataProtection = {};
      this.osTypeId = "10";
      this.flag = 3;
    },
    //新增Windows策略
    addWindows() {
      this.dataProtection = {};
      this.osTypeId = "30";
      this.flag = 4;
    },
    //新增Linux策略
    addLinux() {
      this.dataProtection = {};
      this.osTypeId = "40";
      this.flag = 5;
    },
    // 高度
    heightCustom() {
      this.height.height = this.num;
    },
    //表格当删除发生变化
    handleSelectionChange(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    checkChange(selection, row) {
      let arr1 = [];
      selection.forEach((item) => {
        arr1.push(item.id);
        //去重
        this.delarr = [...new Set(arr1)];
      });
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; //点击出现详情弹框
      this.visibaelFlag = false; //提示消息弹框关闭
      this.ipt = "";
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false;
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.backLoces();
          if (this.submitFlag) {
            this.save();
          }
        } else {
          return false;
        }
      });
    },
    // 保存1
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  width: 100px;
  margin-left: 112px;
}
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 47px;
  left: 10px;
  z-index: 999;

  // background:rgba(255, 255, 255,.1)
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.tabs {
  height: 580px;
  border: 1px solid #dddd;
  margin-left: 10px;
  margin-top: 30px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  border-radius: 5px;
}

.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #807e7e;
  line-height: 47px !important;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
