<template>
    <div>
      <div class="box">
        <p style="padding-left: 122px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.AccessControl')}}</p>
        <div>
          <el-form ref="form" label-width="140px" class="demo-ruleForm" style="padding: 0 120px">
            <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.SecurityGateway')">
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.SecurityGatewayContent')}}
                </div>
                <img src="../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
              </el-tooltip>
              <el-switch @change="test" active-value="1" inactive-value="0" style="padding-left: 15px" v-model="permission.chats.use"></el-switch>
              <div class="tableBox" v-if="flag">
                <el-form-item :label=" $t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.ListOfAccessibleGateways')" style="margin-left: -126px; position: relative" required>
                  <div style="display: flex; margin-left: 14px">
                    <div style="width: 537px; margin: 8px 10px 0 9px">
                      <el-table ref="multipleTable" :data="tableData" stripe size="mini" tooltip-effect="dark"  class="tableClass"  :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle"  :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding:'0px'}" style="width: 100%">
                        <el-table-column header-align="left" align="left" prop="gwName" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.GatewayName')" width="120" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column header-align="left" align="left" prop="gwAddress" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.GatewayAddress')" width="120" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column header-align="left" align="left" prop="gwPort" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.port')" width="70" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column header-align="left" align="left" prop="resourceNames" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.ProtectedServiceResources')" width="120" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column header-align="left" align="left" prop="" :label="$t('public.Controls')" show-overflow-tooltip>
                          <template v-slot="{ row }">
                            <img @click="delapp(row)" src="@/images/icon_del.png" alt="" style="
                          width: 15px;
                              height: 15px;
                              margin-left: 5px;
                              cursor: pointer;
                            " />
                          </template>
  
                          <!-- </template> -->
                        </el-table-column>
                      </el-table>
                    </div>
                    <div>
                      <el-button size="mini" type="primary" @click="addData" icon="el-icon-plus">{{$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.AddGateway')}}</el-button>
                    </div>
  
                  </div>
                </el-form-item>
                <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.MaximumDailyRequestTrafficVolume')" style="margin-left: -126px; position: relative">
                  <el-input v-model.trim="permission.chats.maxRequest" placeholder="1~99999" maxlength="5" @input="handleChange" size="mini" style="width:110px;margin-left:22px;height:20px;"></el-input>&nbsp;MB
                </el-form-item>
                <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.MaximumTransmissionRate')" style="margin-left: -126px; position: relative">
                  <el-input v-model.trim="permission.chats.maxRate" placeholder="1~99999" maxlength="5" @input="handleChange1" size="mini" style="width:110px;margin-left:22px;height:20px;"></el-input>&nbsp;KB/s
                </el-form-item>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <Eldialog :num="'470px'" :width="'900px'" :title="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.GatewayPopUp')" :dialogVisible="statusVisible" @handleClose="handleClose" :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="joinType">
        <div>
          <el-input v-model="ipt" @input="(e) => (ipt = validForbid(e))" @keyup.enter.native="onsearch" size="small" style="width: 180px; margin-bottom: 10px" :placeholder="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.placeholderPopUp')"></el-input>
          <el-button @click="onsearch" type="primary" size="small" style="margin-left: 3px">{{$t('public.Inquire')}}</el-button>
          <el-table size="mini" stripe ref="multipleTables" :data="detailData" :row-key="getRowKeys" class="sort_table" tooltip-effect="dark" style="width: 99%" :default-sort="{ prop: 'updateTime', order: 'descending' }" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange">
            <el-table-column :reserve-selection="true" align="center" type="selection" width="55"> </el-table-column>
            <el-table-column prop="gwName" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.GatewayName')" width="120" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="gwAddress" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.GatewayAddress')" width="140" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="gwPort" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.port')" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="resourceNames" :label="$t('AppManage.ApplicationStrategy.CurrentPage.AccessControlTab.ProtectedServiceResources')" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('public.ModificationTime')" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                  {{ scope.row.updateTime | dateformat }}
                </span>
              </template>
            </el-table-column>
          </el-table>
  
          <!-- <p style="margin-top: 15px">
            共<b>{{ this.detailDataTotal }}</b
            >条记录
          </p> -->
          <div>
            <pagination :page="responsedata.currentPage" :limit="responsedata.pageSize" :total="responsedata.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
          </div>
        </div>
      </Eldialog>
    </div>
  </template>
  
  <script>
  import Eldialog from '@/components/elDialog' //详情弹框
  import pagination from '@/components/pagination/page.vue' //分页页
  import { log } from 'console'
  export default {
    components: {
      Eldialog,
      pagination
    },
    props: {
      access: {
        typeof: Object,
        default: () => {}
      }
    },
    data() {
      return {
        gatewayId: [], //刚进去的id
        geofences: [], //网关列表  id
        multipleSelection: [],
        statusVisible: false, //响应动作的隐藏
        detailDataTotal: 2, //弹框  数据长度
        flag: false, //网关资源列表默认隐藏
        //   开关默认数值
        permission: {
          chats: {
            use: '0',
            gwList: [],
            maxRequest:"",
            maxRate:""
          }
        },
        //   访问控制tab页里的  列表
        tableData: [],
        //   访问控制tab页里  按钮  里的 弹框---列表
        detailData: [],
        ipt: '',
        // 响应动的参数
        responsedata: {
          total: 0,
          currentPage: 1, //pageNumber
          pageSize: 10, //rowNumber
          orderColume: 'updateTime',
          orderRule: 'DESC'
        },
        form: {
          // 系统权限限制
          delivery1: false,
          name: ''
        },
        rules: {
          // desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
        }
        // 列表参数
        // listData: {
        //   total: 0,
        //   currentPage: 1, //pageNumber
        //   pageSize: 10, //rowNumber
        //   orderColume: 'lastUpdateTime',
        //   orderRule: 'DESC',
        //   name: ''
        // },
      }
    },
    created() {
      // 应用威胁
      // setTimeout(() => {
      //   this.backRound();
      // }, 500);
    },
    mounted() {
      this.gatewayName() //网关名称
      this.TwoTable()
      setTimeout(() => {
        this.backRound()
      }, 600)
    },
    methods: {
      getRowKeys(row) {
        return row.id
      },
      //表格行高
      iRowStyle: function ({ row, rowIndex }) {
        return 'height:30px'
      },
      iHeaderRowStyle: function ({ row, rowIndex }) {
        return 'height:30px'
      },
      iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
        return 'padding:3px 0px'
      },
      handleChange() {
        // 通过正则表达式判断输入是否符合要求
        if (/^(0|[1-9][0-9]*)$/.test(this.permission.chats.maxRequest)) {
          // 转换为整数并限制范围为1-100
          let num = parseInt(this.permission.chats.maxRequest)
          if (num < 1) {
            this.$message({
              message: '仅支持输入1-99999正整数',
              type: 'error',
              offset: 100,
              duration: 2000
            })
            this.permission.chats.maxRequest = ''
            return false
          } else if (num > 99999) {
            this.$message({
              message: '仅支持输入1-99999正整数',
              type: 'error',
              offset: 100,
              duration: 2000
            })
            this.permission.chats.maxRequest = ''
            return false
          }
          this.permission.chats.maxRequest = num.toString()
        } else {
          // 不符合要求则清空输入框
          this.permission.chats.maxRequest = ''
        }
      },
      handleChange1() {
        if (/^(0|[1-9][0-9]*)$/.test(this.permission.chats.maxRate)) {
          // 转换为整数并限制范围为1-100
          let num = parseInt(this.permission.chats.maxRate)
          if (num < 1) {
            this.$message({
              message: '仅支持输入1-99999正整数',
              type: 'error',
              offset: 100,
              duration: 2000
            })
            this.permission.chats.maxRate = ''
            return false
          } else if (num > 99999) {
            this.$message({
              message: '仅支持输入1-99999正整数',
              type: 'error',
              offset: 100,
              duration: 2000
            })
            this.permission.chats.maxRate = ''
            return false
          }
          this.permission.chats.maxRate = num.toString()
        } else {
          // 不符合要求则清空输入框
          this.permission.chats.maxRate = ''
        }
      },
      backRound() {
        // this.joinType() //this.permission.gwList
        if (this.access) {
          // this.access.appPermission.permission.gateway.sensitive.gwList
          this.gatewayId = this.access.appPermission.permission.gateway
  
          if (
            this.permission.chats != this.access.appPermission.permission.gateway
          ) {
            this.detailData.forEach((item) => {
              this.access.appPermission.permission.gateway.gwList.forEach(
                (newItem) => {
                  if (item.id == newItem) {
                    this.tableData.push({
                      id: item.id,
                      gwName: item.gwName,
                      gwAddress: item.gwAddress,
                      gwPort: item.gwPort,
                      resourceNames: item.resourceNames
                    })
                  }
                }
              )
            })
            this.permission.chats = this.access.appPermission.permission.gateway
            this.test(this.permission.chats.use)
          }
        }
      },
      test(val) {
        console.log(val,'网关');
        if (val == 1) {
          this.flag = true
        } else {
          this.flag = false
        }
        this.$emit('accessControl', this.permission)
      },
      // 点击 ‘添加网关’  弹框出现
      addData() {
        this.statusVisible = true
        this.gatewayName()
      },
      // 搜索  弹框
      onsearch() {
        // onsearch
        this.gatewayName()
      },
      // 列表
      async gatewayName() {
        if (this.ipt.length > 0) {
          this.responsedata.currentPage = 1
        }
        var param = {
          currentPage: this.responsedata.currentPage,
          pageSize: this.responsedata.pageSize,
          // orderColume: this.responsedata.orderColume,
          // orderRule: this.responsedata.orderRule,
          gwName: this.ipt,
          ids: []
        }
        const res = await this.$axios.post(
          '/httpServe/sdp/gateway/getDataInfo',
          param,
          true
        )
        this.detailData = res.data.content //列表数据 
        // this.dataTolCount = res.data.total; //列表总数
        this.responsedata.total = res.data.total //列表总数
        this.$nextTick(() => {
          this.detailData.forEach((item, index) => {
            this.tableData.forEach((i, j) => {
              if (item.id == i.id) { 
                // this.$refs.multipleTables.toggleRowSelection(item, true)
              }
            })
          })
        })
        this.$emit('accessControl', this.permission)
      },
      // 列表
      async TwoTable() {
        if (this.ipt.length > 0) {
          this.responsedata.currentPage = 1
        }
        var param = {
          currentPage: this.responsedata.currentPage,
          pageSize: 999,
          // orderColume: this.responsedata.orderColume,
          // orderRule: this.responsedata.orderRule,
          gwName: this.ipt,
          ids: []
        }
        const res = await this.$axios.post(
          '/httpServe/sdp/gateway/getDataInfo',
          param,
          true
        )
        this.$nextTick(() => {
          this.detailData = res.data.content //列表数据
        })
  
        // this.dataTolCount = res.data.total; //列表总数
        this.responsedata.total = res.data.total //列表总数
        // this.$nextTick(() => {
        //   this.detailData.forEach((item, index) => {
        //     this.tableData.forEach((i, j) => {
        //       if (item.id == i.id) { 
        //         this.$refs.multipleTables.toggleRowSelection(item, true)
        //       }
        //     })
        //   })
        // })
        this.$emit('accessControl', this.permission)
      },
      //排序
      sort(column) {
        if (column.order === 'ascending') {
          this.responsedata.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.responsedata.orderRule = 'DESC'
        }
        this.responsedata.orderColume = column.prop
        this.responseBtn()
      },
      // 弹框点X击关闭事件
      handleClose() {
        this.statusVisible = false //点击出现详情弹框
        this.ipt = ''
        this.responsedata.currentPage = 1
        this.$refs.multipleTables.clearSelection()
      },
      //弹框中列表 change的改变事件
      handleSelectionChange(val) {
        console.log(val,'啦啦啦啦阿里');
        this.multipleSelection = [...val]
      },
  
      // 加入 弹框
      joinType() {
        // id: item.id,
        // gwName: item.gwName,
        // tableData   dictData
        // this.tableData=[]
        console.log( this.multipleSelection,'拉拉la');
        this.multipleSelection.forEach((item, index) => {
          this.tableData.push({
            id: item.id,
            gwName: item.gwName,
            gwAddress: item.gwAddress,
            gwPort: item.gwPort,
            resourceNames: item.resourceNames
          })
        })
        console.log(this.tableData,'外面的列表');
        var result = []
        var obj = {}
        for (var i = 0; i < this.tableData.length; i++) {
          if (!obj[this.tableData[i].gwName]) {
            result.push(this.tableData[i])
            obj[this.tableData[i].gwName] = true
          }
        }
        this.tableData = result
        console.log(this.tableData,'最后加入的结果');
        this.geofences = []
        this.tableData.forEach((item, index) => {
          this.geofences.push(item.id + '')
        })
        this.permission.chats.gwList = this.geofences
        this.statusVisible = false
        this.$refs.multipleTables.clearSelection()
        this.$emit('accessControl', this.permission)
      },
      // 删除
      delapp(val) { 
         console.log(val,'删除')
        var del_result=this.tableData.filter(item => item.gwName != val.gwName)
        console.log(del_result,'-del_result')
        this.tableData=del_result
        var arr=[]
         this.tableData.forEach((item, index) => {
          
          arr.push(item.id)
        })
        this.permission.chats.gwList=arr
        console.log(this.tableData,'-this.tableData') 
        this.$refs.multipleTables?.clearSelection()
         this.$emit("accessControl", this.permission);
       },
      //分页
      handleParentGetList(page, limit) {
        this.responsedata.currentPage = page
        this.responsedata.pageSize = limit
        this.gatewayName()
      }
    }
  }
  </script>
  
  <style  lang="scss" scoped> 
  
  
  </style>