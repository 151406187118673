<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.CrashTestingTab.CrashMonitoring')}}</p>
      <div>
        <el-form
          ref="form"
          label-width="151px"
          class="demo-ruleForm"
          style="padding: 0 120px"
        >
          <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.CrashTestingTab.RecordCrashInformation')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('AppManage.ApplicationStrategy.CurrentPage.CrashTestingTab.MonitorApplicationCrashes')}} 
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              active-value="1"
              inactive-value="0"
              @change="test"
              style="padding-left: 15px"
              v-model="permission.crash.use"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    crashtest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        // 系统权限限制
        use: false,
      },
      permission: {
        crash: {
          use: "0",
        },
      },
    };
  },
  created() {
    if(this.crashtest){
      if (
      this.permission.crash != this.crashtest.appPermission.permission.crash
    ) {
      this.permission.crash = this.crashtest.appPermission.permission.crash;
      this.permission.crash.use =
        this.crashtest.appPermission.permission.crash.use;
      this.test(this.permission.crash.use);
    }
    }
    // 崩溃检测
  },
  methods: {
    test(val) {  
      this.$emit("bengkui", this.permission);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
   margin-bottom: 0px; 
}
::v-deep .el-form .el-form-item {
  margin-bottom: 0px !important;
}
</style>