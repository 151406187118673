<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.NetworkSecurityControl')}}</p>
      <div>
        <el-form
          ref="form"
          label-width="151px"
          class="demo-ruleForm"
          style="padding: 0 120px"
        >
          <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.network')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.networkContent1')}}<br/>{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.networkContent2')}}
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.net.use"
            ></el-switch>
          </el-form-item>
          <div v-show="flagtrue">
            <el-form ref="form" label-width="200px" style="margin-left: -22px;">
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.TurnOnOffMobileNetwork')">
                <el-checkbox
                  style="margin-left:15px"
                  v-model="permission.net.mobile_switch.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.net.mobile_switch.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left:30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left:30px"
                  v-model="permission.net.mobile_switch.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.UsingTheMobileWeb')">
                <el-checkbox
                  style="margin-left:15px"
                  v-model="permission.net.mobile.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.net.mobile.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left:30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left:30px"
                  v-model="permission.net.mobile.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio1(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio1(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio1(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio1(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.TurnOnOffWiFiNetwork')">
                <el-checkbox
                  style="margin-left:15px"
                  v-model="permission.net.wifi_switch.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.net.wifi_switch.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left:30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left:30px"
                  v-model="permission.net.wifi_switch.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio2(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio2(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio2(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio2(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.UseWiFiNetwork')">
                <el-checkbox
                  style="margin-left:15px"
                  v-model="permission.net.wifi.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.net.wifi.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left:30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left:30px"
                  v-model="permission.net.wifi.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio3(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio3(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio3(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio3(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.Bluetooth')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.BluetoothContent1')}}<br/>{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.BluetoothContent2')}}
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test1"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.bluetooth.use"
            ></el-switch>
          </el-form-item>
          <div v-show="blueya">
            <el-form ref="form" label-width="200px" style="margin-left: -22px;">
              <el-form-item v-if="type!=='ios'" :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.TurnBluetoothOnOff')">
                <el-checkbox
                  style="margin-left:15px"
                  v-model="permission.bluetooth.blue_switch.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.bluetooth.blue_switch.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left:30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left:30px"
                  v-model="permission.bluetooth.blue_switch.action"
                  @change="checkbox"
                >
                 <el-radio :label="4" @click.native.prevent="checkboxRadio4(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio4(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio4(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio4(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.UsingBluetoothTransmission')">
                <el-checkbox
                  style="margin-left:15px"
                  v-model="permission.bluetooth.useBlueTooth.forbid"
                  true-label="1"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.prohibit')}}</el-checkbox
                >
                <el-checkbox
                  v-model="permission.bluetooth.useBlueTooth.collect"
                  true-label="16"
                  false-label="0"
                  @change="checkbox"
                  >{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.CollectionBehavior')}}</el-checkbox
                >
                <span style="margin-left:30px;color:#606266">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ResponseAction')}}</span>
                <el-radio-group
                  style="margin-left:30px"
                  v-model="permission.bluetooth.useBlueTooth.action"
                  @change="checkbox"
                >
                  <el-radio :label="4" @click.native.prevent="checkboxRadio5(4)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.AlarmMessage')}}</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio5(8)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ClearData')}}</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio5(2)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.ExitApplication')}}</el-radio>
                  <el-radio :label="64" @click.native.prevent="checkboxRadio5(64)">{{$t('AppManage.ApplicationStrategy.CurrentPage.NetworkSecurityTab.DenyAccess')}}</el-radio>
                </el-radio-group>
              </el-form-item> 
            </el-form>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    anquan:{
      type:Object,
      default:()=>{}
    },
    type:{
       type:String,
       default:""
     }
  },
  data() {
    return {
      flagtrue: false,
      blueya:false,
      permission: {
        net: {
          use: "0",
          mobile_switch: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          mobile: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          wifi_switch: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          wifi: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
        },
        bluetooth: {
          use: "0",
          blue_switch: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
          useBlueTooth: {
            forbid: "0",
            collect: "0",
            action: "0",
          },
        },
      },
    };
  },
  created(){
     // 界面水印
     if(this.anquan){
       if(this.permission.net != this.anquan.appPermission.permission.net){ 
        this.permission.net=this.anquan.appPermission.permission.net
      this.permission.net.use=this.anquan.appPermission.permission.net.use
      this.test( this.permission.net.use)
    }
    // 文件透明加解密
    if(this.permission.bluetooth != this.anquan.appPermission.permission.bluetooth){
       this.permission.bluetooth=this.anquan.appPermission.permission.bluetooth
      this.permission.bluetooth.use=this.anquan.appPermission.permission.bluetooth.use
      this.test1( this.permission.bluetooth.use)
    }
     }
    
  },
  methods: {
     checkboxRadio(value){
      this.permission.net.mobile_switch.action = value == this.permission.net.mobile_switch.action ? '' : value 
    },
    checkboxRadio1(value){
      this.permission.net.mobile.action = value == this.permission.net.mobile.action ? '' : value 
    },
    checkboxRadio2(value){
      this.permission.net.wifi_switch.action = value == this.permission.net.wifi_switch.action ? '' : value 
    },
    checkboxRadio3(value){
      this.permission.net.wifi.action = value == this.permission.net.wifi.action ? '' : value 
    },
    checkboxRadio4(value){
      this.permission.bluetooth.blue_switch.action = value == this.permission.bluetooth.blue_switch.action ? '' : value 
    },
    checkboxRadio5(value){
      this.permission.bluetooth.useBlueTooth.action = value == this.permission.bluetooth.useBlueTooth.action ? '' : value 
    },
    test(val) { 
      if(this.permission.net.use==1){
        this.flagtrue=true
      }else{
        this.flagtrue=false
      }
      this.$emit("network", this.permission);
    },
    test1(val) { 
      if(this.permission.bluetooth.use==1){
        this.blueya=true
      }else{
        this.blueya=false
      } 
      this.$emit("network", this.permission);
    },
    checkbox(val) {
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 0px !important;
}
::v-deep .el-form-item__label .ll {
  padding: 0px 0px 0 0 !important;
}
</style>