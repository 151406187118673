<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px">应用权限：</p>
      <div>
        <el-form
          ref="form"
          label-width="151px"
          class="demo-ruleForm"
          style="padding: 0 120px"
        >
          <div
            style="
              margin-left: 50px;
              color: #606266;
              font-size: 14px;
              font-family: Microsoft YaHei;
              margin-bottom: 10px;
              margin-top: 10px;
            "
          >
            证书认证
            <!-- <el-form-item label="证书认证"> -->
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                使用应用需要证书认证通过
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.app.cerAuth"
            ></el-switch>
            <!-- </el-form-item> -->
          </div>
          <div
            style="
              margin-left: 50px;
              color: #606266;
              font-size: 14px;
              font-family: Microsoft YaHei;
              margin-bottom: 10px;
            "
          >
            使用权限
            <!-- <el-form-item label="使用权限"> -->
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                应用仅限于分配对象使用
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.app.permission"
            ></el-switch>
            <!-- </el-form-item> -->
          </div>
          <div
            style="
              margin-left: 50px;
              color: #606266;
              font-size: 14px;
              font-family: Microsoft YaHei;
              margin-bottom: 10px;
            "
          >
            安全隧道
            <!-- <el-form-item label="安全隧道"> -->
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                保护网络传输数据的保密性和完整性
              </div>
              <img
                src="../../../../assets/question.png"
                alt=""
                srcset=""
                style="padding-left: 8px"
              />
            </el-tooltip>
            <el-switch
              @change="test"
              active-value="1"
              inactive-value="0"
              style="padding-left: 15px"
              v-model="permission.app.sshTunnel"
            ></el-switch>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appa: {
      typeof: Object,
      default: () => {},
    },
  },
  data() {
    return {
      permission: {
        app: {
          cerAuth: "0",
          permission: "0",
          sshTunnel: "0",
        },
      },
    };
  },
  created() {
    // 应用威胁
    if(this.appa){
      if (this.permission.app != this.appa.appPermission.permission.app) {
      this.permission.app = this.appa.appPermission.permission.app;
      this.permission.cerAuth = this.appa.appPermission.permission.cerAuth;
      this.permission.permission = this.appa.appPermission.permission.permission;
      this.permission.sshTunnel = this.appa.appPermission.permission.sshTunnel;
      this.test(this.permission);
    }
    }
  },
  methods: {
    // 证书认证
    test(val) {
      this.$emit("appQuanXian", this.permission);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 0px !important;
}
</style>