<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px">{{$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.ConductAudit')}}</p>
      <div>
        <el-form ref="form" label-width="151px" class="demo-ruleForm" style="padding: 0 120px">
          <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.ChatEnterInformation')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.RecordChatMessagesOrClientInput')}}
              </div>
              <img src="../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch @change="test" active-value="1" inactive-value="0" style="padding-left: 15px" v-model="permission.chats.use"></el-switch>
          </el-form-item>
          <div class="bigbigbox" v-show="xwflag">
            <el-form-item label="">
              <p>
                <el-checkbox true-label="1" false-label="0" v-model="permission.chats.text" @change="checkenbel" style="padding-left: 45px">{{$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.RecordChatInputInformation')}}</el-checkbox>
              </p>
              <p>
                <el-checkbox true-label="1" false-label="0" v-model="permission.chats.sensitive.use" @change="messageci" style="padding-left: 45px">{{$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.RecordChatEnterInformationSensitiveWords')}}</el-checkbox>
              </p>
            </el-form-item>
            <el-form-item :label="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.ListOfThesaurus')" style="margin-left: 134px; position: relative" v-show="flagTbale">
              <span style="
                  position: absolute;
                  top: 0px;
                  left: -92px;
                  color: #f56c6c;
                ">*</span>
              <div style="display: flex">
                <div style="width: 500px; margin: 8px 10px 0 0px">
                  <el-table ref="multipleTable" :data="dictData" stripe size="mini" tooltip-effect="dark" class="tableClass" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
                    <el-table-column header-align="left" align="left" prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.LexicalName')" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column header-align="left" align="left" prop="" :label="$t('public.Controls')" show-overflow-tooltip>
                      <template v-slot="{ row }">
                        <img @click="delapp(row)" src="@/images/icon_del.png" alt="" style="
                            width: 15px;
                            height: 15px;
                            margin-left: 5px;
                            cursor: pointer;
                          " />
                      </template>

                      <!-- </template> -->
                    </el-table-column>
                  </el-table>
                </div>
                <span>
                  <el-button size="mini" type="primary" @click="functionDetail" icon="el-icon-plus">{{ $t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.SensitiveLexicon') }}</el-button>
                </span>
              </div>
            </el-form-item>

            <el-form-item label="" v-show="flagTbale">
              <p>
                <el-checkbox true-label="1" false-label="0" v-model="permission.chats.sensitive.replace" @change="checkenbel" style="padding-left: 45px">{{$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.DoNotEnterSensitiveWords')}}</el-checkbox>
              </p>
              <p>
                <el-checkbox true-label="16" false-label="0" v-model="permission.chats.sensitive.collect" @change="checkenbel" style="padding-left: 45px">{{$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.AuditChatEnterInformationSensitiveWords')}}</el-checkbox>
              </p>
              <p style="margin-left: 50px; color: #606266">{{ $t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.ResponseAction') }}</p>
              <p>
                <el-radio-group v-model="permission.chats.sensitive.action" @change="checkenbel" style="margin-left: 50px">
                  <el-radio :label="4" @click.native.prevent="checkboxRadio1(4)">{{ $t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.AlarmMessage') }}</el-radio>
                </el-radio-group>
              </p>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <Eldialog :num="'470px'" :title="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.pleaseSensitiveLexicon')" :dialogVisible="statusVisible" @handleClose="handleClose" @heightCustom="heightCustom" :btnTitle="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.btnTitle')" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="joinType">
      <el-input v-model="ipt" @keyup.enter.native="onSbumit" size="small" style="width: 180px; margin-bottom: 10px" :placeholder="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.placeholder')"></el-input>
      <el-button @click="onSbumit" type="primary" size="small" style="margin-left: 3px">{{$t('public.Inquire')}}</el-button>
      <el-table size="mini" stripe ref="multipleTables" :data="tableDataNmae" class="tableClass" tooltip-effect="dark" style="width: 97%" :default-sort="{ prop: 'updateTime', order: 'descending' }" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange" @sort-change="sort">
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column prop="name" :label="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.LexicalName')" sortable="custom" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="words" :label="$t('AppManage.ApplicationStrategy.CurrentPage.BehaviorAuditTab.SensitiveWords')" width="220" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column sortable="custom" prop="updateTime" :label="$t('public.ModificationTime') " :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.updateTime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; width: 565px; justify-content: space-between">
        <p style="margin-top: 15px">
          {{$t('public.total')}}<b>{{ this.dataTolCount }}</b>{{$t('public.recordss')}}
        </p>
      </div>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from '@/components/elDialog' //详情弹框
export default {
  components: {
    Eldialog
  },
  props: {
    audit: {
      typeof: Object,
      default: () => {}
    }
  },
  created() {
    // 应用威胁
    setTimeout(() => {
      this.backRound()
    }, 500)
    this.responseBtn()
    // this.responseBtn2()
  },
  data() {
    return {
      waterId: [],
      dataForm: [],
      geofences: [],
      flagOp: false,
      permission: {
        chats: {
          use: '0',
          text: '0',
          sensitive: {
            use: 0,
            replace: '0',
            collect: '0',
            action: '0',
            library: []
          }
        }
      },

      tableDate: [],
      ipt: '', //收缩
      dataTolCount: 0, //共多少条记录
      statusVisible: false, //响应动作的隐藏
      xwflag: false,
      dictData: [], //记录聊天敏感词 响应动作  如果是[{}]就会多一个空数据
      dictDataItem: null, //????????
      tableDataNmae: [], //响应的列表
      flagTbale: false,
      form: {
        // 系统权限限制
        delivery1: false,
        name: ''
      },
      checked1: '',
      checked2: '',

      xinchecked1: '', //记录聊天信息
      ganchecked2: '', //记录聊天敏感词

      cjxwradio1: '', //响应动作  采集行为  单选

      enbelchecked1: '', //禁止输入敏感词
      shenchecked2: '', //审计聊天敏感词
      gjradio1: '', //告警提示  单选
      // 响应动的参数
      responsedata: {
        pageNumber: 1,
        rowNumber: 100000,
        orderColume: 'updateTime',
        orderRule: 'DESC'
      },
      rules: {
        name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
      },
      listData: {
        type: '20',
        action: 'edit',
        pageNumber: 1,
        rowNumber: 100000,
        where: {
          searchKey: 'name',
          searchContent: ''
        },
        sort: 'updateTime desc'
      }
    }
  },
  methods: {
    checkboxRadio1(value) {
      this.permission.chats.sensitive.action =
        value == this.permission.chats.sensitive.action ? '' : value
    },
    //排序
    sort(column) {
      if (column.order === 'ascending') {
        this.responsedata.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.responsedata.orderRule = 'DESC'
      }
      this.responsedata.orderColume = column.prop
      this.responseBtn()
    },
    onSbumit() {
      this.responseBtn()
    },
    backRound() {
      if (this.audit) {
        this.waterId =
          this.audit.appPermission.permission.chats.sensitive.library
        if (
          this.permission.chats != this.audit.appPermission.permission.chats
        ) {
          this.tableDataNmae.forEach((item) => {
            this.audit.appPermission.permission.chats.sensitive.library.forEach(
              (newItem) => {
                if (item.id == newItem) {
                  this.dictData.push({
                    id: item.id,
                    name: item.name
                  })
                }
              }
            )
          })
          this.permission.chats = this.audit.appPermission.permission.chats
          this.test(this.permission.chats.use)
          this.messageci(this.permission.chats.sensitive.use)
        }
      }
    },
    test(val) {
      if (this.permission.chats.use == 1) {
        this.xwflag = true
      } else {
        this.xwflag = false
      }
      this.$emit('xingwei', this.permission)
    },
    // 记录聊天敏感词
    messageci(val) {
      if (this.permission.chats.sensitive.use == 1) {
        this.flagTbale = true
      } else {
        this.flagTbale = false
      }
      this.$emit('xingwei', this.permission)
    },

    // 词库列表的响应动作按钮
    functionDetail() {
      this.statusVisible = true //点击出现详情弹框
      this.responseBtn()
    },
    // /sensitive_words/selectNameList
    async responseBtn2() {
      var param = {
        ids: [this.dictData.id + '']
      }
      const res = await this.$axios.post(
        '/httpServe/sensitive_words/selectNameList',
        param,
        true
      )
      this.dictData = res.data //列表数据 ;
    },
    async responseBtn() {
      var param = {
        currentPage: this.responsedata.pageNumber,
        pageSize: 9999,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.ipt,
        words: ''
      }
      const res = await this.$axios.post(
        '/httpServe/sensitive_words/getDataInfo',
        param,
        true
      )
      this.tableDataNmae = res.data.content //列表数据
      this.dataTolCount = res.data.total //列表总数
      if(this.dictData){
        this.$nextTick(() => {
        this.tableDataNmae.forEach((item, index) => {
          this.dictData.forEach((i, j) => {
            if (item.id == i.id) {
              this.$refs.multipleTables.toggleRowSelection(item, true)
            }
          })
        })
      })
      }
      this.$emit('xingwei', this.permission)
    },
    // change的改变事件
    handleSelectionChange(val) {
      this.multipleSelection = [...val]
      console.log(this.multipleSelection,'啦啦啦啦啦回显')
    },
    // 加入
    joinType() {
      this.dictData = []
      this.multipleSelection.forEach((item, index) => {
        this.dictData.push({
          id: item.id,
          name: item.name
        })
      })
      var result = []
      var obj = {}
      for (var i = 0; i < this.dictData.length; i++) {
        if (!obj[this.dictData[i].name]) {
          result.push(this.dictData[i])
          obj[this.dictData[i].name] = true
        }
      }
      this.dictData = result
      this.geofences = []

      this.dictData.forEach((item, index) => {
        this.geofences.push(item.id)
      })
      this.permission.chats.sensitive.library = this.geofences
      this.statusVisible = false
      this.$emit('xingwei', this.permission)
    },
    // 删除
    delapp(val) {
      var del_result=this.dictData.filter(item => item.name != val.name)
      console.log(del_result,'-del_result')
      this.dictData=del_result
      var arr=[]
       this.dictData.forEach((item, index) => {
        
        arr.push(item.id)
      })
      this.permission.chats.sensitive.library=arr
      this.$emit('xingwei', this.permission)
    },
    // 高度
    heightCustom() {
      this.height.height = this.num
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false //点击出现详情弹框
      this.ipt = ''
    },
    // 记录聊天信息
    messagexi(val) {},

    // 响应动作  单选
    checkenbel(val) {
      this.$emit('xingwei', this.permission)
    },
    // 响应动作  单选
    shenji(val) {
      // console.log(val, "审计聊天敏感词");
    },
    // 响应动作  单选
    gaojing(val) {
      // console.log(val, "告警提示");
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-table__empty-text {
  line-height: 60px;
  width: 66%;
  color: #909399;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 0px !important;
}
</style>
